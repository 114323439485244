@use "static/sass/theme" as theme;

.btn {
	border: 1px solid transparent;
	transition: all theme.$app-transition-time ease;
	text-transform: uppercase;

	&.remove {
		color: theme.$remove-color;
		border-color: theme.$remove-color;
		background-color: transparent;

		&:hover:not(:disabled) {
			color: darken(theme.$remove-color, 5%);
			border-color: darken(theme.$remove-color, 5%);
		}
	}

	&:not(:disabled) {
		&:hover,
		&:focus,
		&:active,
		&:focus-within {
			border: theme.$box-border-hover;
		}
	}
}
