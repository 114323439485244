@use "static/sass/theme";

.user-profile {
	position: relative;
	display: inline-block;

	.profile-button {
		color: theme.$app-header-color;
		font-weight: theme.$font-weight-bolder;
		background-color: transparent;
		border: none;
		cursor: pointer;
		border: theme.$button-border-width-thick solid theme.$app-header-color;
		transition: all theme.$app-transition-time ease-in-out;
		font-family: theme.$font-family;
		padding: calc(0.2 * theme.$app-box-padding)
			calc(0.4 * theme.$app-box-padding)
			calc(0.1 * theme.$app-box-padding)
			calc(0.4 * theme.$app-box-padding);
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;

		&:hover,
		&:active,
		&:focus,
		&:focus-visible,
		&:focus-within {
			color: theme.$app-header-color-hover;
			// background-color: theme.$button-p; // Simple hover effect
			border: theme.$button-border-width-thick solid
				theme.$app-header-color;
			text-decoration: underline;
		}

		i.icon {
			font-size: 1.4em;
			font-weight: theme.$font-weight-bolder;
		}

		span.profile-initials {
			font-size: 1em;
			width: 40px;
			height: 40px;
			border-radius: 50%; // Makes the image circular
		}

		img.profile-pic {
			width: 40px;
			height: 40px;
			border-radius: 50%; // Makes the image circular
		}
	}

	.dropdown {
		position: absolute;
		top: 100%;
		right: 0;
		z-index: 1;
		min-width: 250px;
		background-color: theme.$dropdown-background-color;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
		border-radius: theme.$dropdown-border-radius;
		border: 1px solid theme.$dropdown-border-color;

		button {
			font-size: theme.$button-font-size;
			background-color: theme.$dropdown-background-color;
			border: none;
			cursor: pointer;
			text-align: left;
			padding: 12px theme.$app-box-padding;
			width: 100%;
			transition: all theme.$app-transition-time ease-in-out;

			&:hover {
				color: theme.$dropdown-color-hover;
				background-color: theme.$dropdown-background-color-hover;
			}
		}

		.ms-Dropdown .ms-Dropdown-title,
		.ms-Dropdown span.ms-Dropdown-optionText {
			font-size: theme.$button-font-size !important;
		}

		&-divider {
			height: 2px;
			background-color: theme.$app-box-border-color;
			margin: calc(theme.$app-box-margin / 2) 0;
		}

		&-info {
			padding: calc(theme.$app-box-padding * 0.5) theme.$app-box-padding;
			color: theme.$dropdown-color;
			font-size: theme.$button-font-size;
			background-color: theme.$dropdown-background-color;
		}

		.combo-box-padding {
			padding: 0 theme.$app-box-padding;
			display: none; // Hide by default on desktop/tablet

			@media screen and (max-width: theme.$breakpoint-width-mobile-small) {
				display: block; // Show only on mobile
			}

			select {
				border: unset;
			}
		}
	}
}
