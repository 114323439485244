@use "static/sass/theme" as theme;

.dropdown-group {
	//flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	margin-bottom: calc(0.5 * theme.$app-box-margin);

	label {
		margin-bottom: calc(0.5 * theme.$app-box-margin) !important;
	}

	.dropdown-container {
		margin-top: calc(theme.$app-box-margin * 0.5) !important;
	}
}
