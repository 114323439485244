@use "static/sass/theme" as theme;

$animation-duration-500: 500ms;
$animation-duration-160: 160ms;
$animation-duration-1: 1s;
$animation-duration-2: 2s;
$animation-duration-3: 3s;

@keyframes appWarninglightenBackground {
	0% {
		background-color: theme.$app-warning-background-color;
	}
	50% {
		background-color: theme.$app-warning-background-color-blink;
	}
	100% {
		background-color: theme.$app-warning-background-color;
	}
}

@keyframes appPendinglightenBackground {
	0% {
		background-color: theme.$app-cancel-color;
	}
	50% {
		background-color: theme.$app-cancel-background-color-hover;
	}
	100% {
		background-color: theme.$app-cancel-color;
	}
}

.app-warning-blink {
	animation: appWarninglightenBackground $animation-duration-1 infinite;
}

.app-pending-blink {
	animation: appPendinglightenBackground $animation-duration-1 infinite;
}
