@use "static/sass/theme" as theme;

.ai-function-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
	width: 100%;
	// max-width: 500px;

	& *:not(:last-child) {
		margin-right: theme.$app-box-margin;
	}
}
