@use "static/sass/theme" as theme;
@use "components/shared/modal/Modal" as modal;

// Pre-configure the color variables for using
// in the message modal component for different message types:
// - info
// - warning
// - error
// - success

$message-modal-title-height: 3rem;
$message-modal-actions-height: 2rem;

$background-info: #f0f0f0;
$color-info: #000000;

$background-warning: #f8bf7f;
$color-warning: #272727;

$background-error: #ffcccc;
$color-error: #650b0b;

$background-success: #bbd3bb;
$color-success: #044c04;

.message-modal {
	flex: 0 0 auto;
	min-height: 30vh;
	max-height: 70vh;
	width: 40%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15%;
	overflow: auto;
	z-index: modal.$z-index-modal-container;
	padding: theme.$app-box-padding;
	border-radius: modal.$modal-border-radius;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	transition: all theme.$app-transition-time ease-in-out;
	display: none;
	flex-flow: column nowrap;
	justify-content: stretch;
	align-items: flex-start;

	@media screen and (max-width: theme.$breakpoint-width-tablet) {
		& {
			// position: fixed;
			margin-top: 0;
			height: 30vh !important;
			bottom: -30vh;
			width: 100%;
			position: fixed;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			transition: all theme.$app-transition-time-500 ease-in-out;
		}

		&.open {
			bottom: 0;
		}
	}

	@media screen and (max-width: theme.$breakpoint-width-mobile) {
		& {
			margin-top: 0;
			width: 100%;
			height: 30%;
			bottom: -30%;
			position: fixed;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			transition: all theme.$app-transition-time-500 ease-in-out;
		}

		&.open {
			bottom: 0;
		}
	}

	&.open {
		display: flex;
	}

	&,
	&.info {
		background-color: $background-info;
		color: $color-info;
	}

	&.warning {
		background-color: $background-warning;
		color: $color-warning;
	}

	&.error {
		background-color: $background-error;
		color: $color-error;
	}

	&.success {
		background-color: $background-success;
		color: $color-success;
	}

	&-title {
		flex: 0 0 auto;
		width: 100%;
		font-size: 2rem;
		font-weight: bold;
		// padding: theme.$app-box-padding;
		margin-bottom: theme.$app-box-margin;
		line-height: $message-modal-title-height;
		height: $message-modal-title-height;
	}

	&-content {
		flex: 1 1 auto;
		width: 100%;
	}

	&-actions {
		flex: 0 0 auto;
		width: 100%;
		margin-top: theme.$app-box-margin;
		height: $message-modal-actions-height;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;

		& > * {
			margin-left: theme.$app-box-margin;

			&.btn {
				// border: none;
				// background-color: transparent;

				&:not(:last-child) {
					margin-right: theme.$app-box-margin;
				}
			}
		}
	}

	&-button {
		flex: 0 0 auto;
		z-index: modal.$z-index-modal-button-unit;
		padding: calc(theme.$app-box-padding / 2) theme.$app-box-padding;
		// background-color: theme.$button-primary-background-color;
		// color: theme.$button-primary-color;
		border: none;
		border-radius: theme.$button-border-radius;
		margin: theme.$app-box-margin 0 theme.$app-box-margin
			theme.$app-box-margin;
		opacity: 1;
		text-transform: uppercase;
		transition: opacity theme.$app-transition-time ease-in-out;

		&:hover {
			opacity: 0.88;
		}
	}
}
