@use "static/sass/theme" as theme;

$tab-buttons-height: 56px;

.tab {
	&-container {
		flex: 1;
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: stretch;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&-buttons {
		flex: 0 0 auto;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		padding: 0 0 theme.$app-box-padding 0;
		height: $tab-buttons-height;
		text-transform: uppercase;

		&.flex-wrap {
			flex: 1 0 auto;
			flex-flow: row wrap;
			height: auto;
			padding-bottom: 0;

			& button {
				height: auto;
				margin-bottom: calc(theme.$app-box-margin * 1.5);
			}
		}

		& button {
			font-weight: theme.$font-weight;
			font-size: 1.1em !important;
			font-family: theme.$font-family !important;
			padding: calc(theme.$app-box-padding / 2) theme.$app-box-padding;
			margin-right: theme.$app-box-margin;
			border: 1px solid theme.$button-border-color;
			border-radius: theme.$button-border-radius;
			text-transform: uppercase;
			height: 100%;
			opacity: 0.6;
			transition: all 0.1s ease;

			&:last-child {
				margin-right: 0;
			}

			&:not(:disabled) {
				&:hover,
				&:active,
				&:focus,
				&:focus-visible,
				&:focus-within {
					cursor: pointer;
					background-color: theme.$button-background-color-hover;
					color: theme.$button-color-hover;
					border: 1px solid theme.$button-border-color-hover;
					opacity: 0.9;
					text-decoration: underline;
				}

				&.active {
					background-color: theme.$button-primary-background-color;
					color: theme.$button-primary-color;
					border: 1px solid theme.$button-primary-background-color;
					opacity: 1;
				}
			}

			@media screen and (max-width: theme.$breakpoint-width-tablet) {
				& {
					// margin-right: 0;
					// margin-bottom: calc(theme.$app-box-margin * 1.5);
					height: auto;
				}
			}

			// &:not(.active, &:hover) {
			// 	opacity: 0.6;
			// 	// color: ;
			// }
		}

		// other screen sizes, should render a next-line list continuity
		@media screen and (max-width: theme.$breakpoint-width-tablet) {
			& {
				flex-flow: row wrap;
				height: auto;
				padding-bottom: 0;
			}

			& button {
				margin-bottom: calc(theme.$app-box-margin * 1.5);
			}
		}
	}

	&-list {
		flex: 1 1 auto;
		width: 100%;
		height: calc(100% - $tab-buttons-height);
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		// padding: 0 theme.$app-box-padding 0 0;
		padding: 0;
		overflow: auto;
	}

	&-item {
		flex: 1 1 auto;
		width: 100%;
	}
}
