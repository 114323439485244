// src/components/Dropdown.scss

@use "static/sass/base" as base;
@use "static/sass/theme" as theme;
@use "static/sass/inputs" as inputs;
@import "~@fluentui/react/dist/css/fabric.css";

.dropdown-container {
	position: relative;
	width: 200px;
	// width: 100%;
	font-family: theme.$font-family;
	// font-family: "Segoe UI", sans-serif;
	z-index: base.$z-index-modal-content;
	// position: relative;
	// cursor: pointer;
	min-height: inputs.$input-form-height;

	&:hover {
		border-color: theme.$dropdown-border-color;
	}

	// .dropdown {

	& [class^="ms-Dropdown-title"] {
		border: unset;
		border-radius: inputs.$input-border-radius;
		height: inputs.$input-form-height;
		padding-top: calc(inputs.$input-padding * 0.35);
		font-size: theme.$font-size-large;
	}

	& .ms-Dropdown-caretDownWrapper i {
		padding-top: calc(inputs.$input-padding * 0.2);
		font-size: theme.$font-size-large;
	}

	& .ms-Dropdown[aria-disabled="true"] {
		background-color: theme.$dropdown-background-color-disabled;
	}

	& div[role="combobox"] {
		border: inputs.$input-border;
		border-radius: inputs.$input-border-radius;
		margin-top: calc(theme.$app-box-margin * 0.5);
		margin-bottom: calc(theme.$app-box-margin * 0.5);
	}

	.loader {
		padding: 10px;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		width: 100%;
		border: 1px solid #ccc;
		border-top: none;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		background-color: theme.$dropdown-background-color;

		.selected-option {
			padding: 10px;
			font-weight: bold;
		}

		.options-list {
			list-style: none;
			padding: 0;
			margin: 0;
			max-height: 150px;
			overflow-y: auto;

			li {
				padding: 10px;
				cursor: pointer;

				&.selected {
					background-color: theme.$dropdown-selected-background-color;
				}

				&:hover {
					background-color: theme.$dropdown-background-color-hover;
				}
			}
		}
	}

	&.open {
		.dropdown-content {
			display: block;
		}
	}
	// }
}
