@use "static/sass/theme" as theme;
@use "static/sass/colors" as colors;
@use "static/sass/inputs" as inputs;

.beebase-time-picker {
	display: flex;
	flex-direction: column;
	margin-bottom: calc(1 * theme.$app-box-margin);

	label {
		margin-bottom: calc(1.3 * theme.$app-box-margin) !important;
	}

	.ms-ComboBox {
		border: 1px solid colors.$input-border-color;
		border-radius: theme.$border-radius-medium;
		background-color: colors.$white;

		@media screen and (max-width: theme.$breakpoint-width-mobile) {
			& {
				width: 100%;
			}
		}

		&:hover {
			border-color: colors.$input-border-color-hover;
		}

		&.is-disabled {
			background-color: colors.$input-background-color-disabled;
			border-color: colors.$input-border-color;
		}

		.ms-ComboBox-Input {
			color: colors.$input-color;
			font-size: theme.$font-size;
			padding: 0 inputs.$input-padding;

			&::placeholder {
				color: colors.$input-placeholder-color;
			}
		}

		.ms-Button--icon i {
			font-size: theme.$font-size-large;
		}

		.ms-Button--icon:hover i {
			background-color: transparent;
			color: theme.$button-color-hover;
		}
	}
}
