@use "static/sass/theme" as theme;

$input-xs-width: 10%;
$input-sm-width: 30%;
$input-md-width: 50%;
$input-lg-width: 70%;
$input-xl-width: 90%;

// Input definitions
$input-margin: calc(theme.$margin-unit * 0.5);
$input-padding: calc(theme.$padding-unit * 0.5);
$input-border-radius: 4px;
$input-font-size: 0.9rem;
$input-line-height: theme.$font-line-height;
$input-form-height: 34px;
$input-border-width: theme.$input-border-width;
$input-border-color: theme.$input-border-color;
$input-border-color-hover: theme.$input-border-color-hover;
$input-border: theme.$input-border;
$input-background-color: theme.$input-background-color;
$input-background-color-hover: theme.$input-background-color-hover;
$input-background-color-disabled: theme.$input-background-color-disabled;
$input-color: theme.$input-color;
$input-color-hover: theme.$input-color-hover;
$input-font-weight: theme.$font-weight;

// Input label definitions
$input-label-font-size: 0.9rem;
$input-label-font-weight: theme.$font-weight-bold;

// mixin of input sizes
@mixin input-size($size) {
	@if $size == xs {
		width: $input-xs-width;
	}
	@if $size == sm {
		width: $input-sm-width;
	}
	@if $size == md {
		width: $input-md-width;
	}
	@if $size == lg {
		width: $input-lg-width;
	}
	@if $size == xl {
		width: $input-xl-width;
	}
}
