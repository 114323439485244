@use "./fonts" as fonts;
@use "./colors" as colors;

// Export necessary variables for external use
@forward "./fonts";
@forward "./colors";

// Wide-level margin, padding and spacing definitions
$margin-unit: 8px;
$padding-unit: 16px;

// Screen breakpoint definitions
$breakpoint-width-mobile: 768px;
$breakpoint-width-mobile-small: 550px;
$breakpoint-width-tablet: 1024px;
$breakpoint-width-desktop: 1280px;
$breakpoint-width-large-desktop: 1440px;
$breakpoint-width-xlarge-desktop: 1920px;

// Basic Body definitions
$app-body-background-color: colors.$app-body-background-color;
$app-body-color: colors.$app-body-color;

// Color system definitions
$app-title-color: colors.$app-title-color;
$app-title-color-hover: colors.$app-title-color-hover;
$app-title-font-size: 1.35rem;
$app-title-height: 36px;

// Box system definitions
$app-box-margin: $margin-unit;
$app-box-margin-top: $app-box-margin;
$app-box-margin-bottom: $app-box-margin;
$app-box-padding: $padding-unit;
$app-box-border-radius-double: 8px;
$app-box-border-radius: 4px;
$app-box-border-width: 1px;
$app-box-border-width-thick: 2px;
$app-box-border-width-thicker: 4px;
$app-box-border-color: colors.$app-box-border-color;
$app-box-border-color-light: colors.$app-box-border-color-light;
$app-box-border-color-hover: colors.$app-box-border-color-hover;
$app-box-border-color-active: colors.$app-box-border-color-active;

// Box shadow definitions
$box-shadow-color: colors.$box-shadow-color;
$box-shadow-color-hover: colors.$box-shadow-color-hover;

// Header definitions
$app-header-box-shadow: 0px 2px 2px $box-shadow-color;
$app-header-color: colors.$app-header-color;
$app-header-color-hover: colors.$app-header-color-hover;
$app-header-border: 1px solid colors.$app-header-border-color;
$app-header-title-color: colors.$app-header-title-color;
$app-header-title-color-hover: colors.$app-header-title-color-hover;
$app-header-background-color: colors.$app-header-background-color;
$app-header-padding: $padding-unit;
$app-header-padding-tablet: calc($app-header-padding * 0.5);
$app-header-height: 68px;
$app-header-height-tablet: 50px;
$app-header-z-index: 100;

// App Content definitions
$app-content-z-index: 90;

// Drawer definitions
$app-drawer-background-color: colors.$app-drawer-background-color;
$app-drawer-background-color-hover: colors.$app-drawer-background-color-hover;
$app-drawer-color: colors.$app-drawer-color;
$app-drawer-border-color: colors.$app-drawer-border-color;
$app-drawer-width: 350px;
$app-drawer-width-mobile: 100vw;
$app-drawer-width-mobile-small: 100vw;

// App Overlay div
$app-overlay-background-color: colors.$app-overlay-background-color;

// Modal definitions
$app-modal-title-height: 80px;

// Font definitions
$font-family: "League Spartan", sans-serif;
// $font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
// 	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
// 	sans-serif;
// $font-family: "AvantGarde Bk BT", sans-serif;
//   font-optical-sizing: auto;
//   font-weight: <weight>;
//   font-style: normal;

$font-size: 16px;
$font-line-height: 1.1rem;
$font-weight: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$font-weight-light: 300;
$font-weight-lighter: 100;

// Button definitions
$button-padding: $padding-unit;
$button-border-radius: 8px;
$button-border-width: $app-box-border-width;
$button-border-width-thick: $app-box-border-width-thick;
$button-border-width-thicker: $app-box-border-width-thicker;
$button-border-color: colors.$button-border-color;
$button-border-color-hover: colors.$button-border-color-hover;
$button-background-color: colors.$button-background-color;
$button-background-color-hover: colors.$button-background-color-hover;
$button-background-color-active: colors.$button-background-color-active;
$button-color-disabled: colors.$button-color-disabled;
$button-color: colors.$button-color;
$button-color-hover: colors.$button-color-hover;
$button-font-weight: $font-weight-bold;
$button-font-size: $font-size;
$button-line-height: $font-line-height;
$button-focus-outline-color: colors.$button-focus-outline-color;

// Button presets by priority
$button-primary-background-color: colors.$button-primary-background-color;
$button-primary-background-color-hover: colors.$button-primary-background-color-hover;
$button-primary-background-color-active: colors.$button-primary-background-color-active;
$button-primary-color: colors.$button-primary-color;
$button-primary-border-color: colors.$button-primary-color;
$button-primary-color-hover: colors.$button-primary-color-hover;
$button-primary-color-active: colors.$button-primary-color-active;
$button-primary-color-disabled: colors.$button-primary-color-disabled;
$button-primary-background-color-disabled: colors.$button-primary-background-color-disabled;

$button-secondary-background-color: colors.$button-secondary-background-color;
$button-secondary-background-color-hover: colors.$button-secondary-background-color-hover;
$button-secondary-background-color-active: colors.$button-secondary-background-color-active;
$button-secondary-color: colors.$button-secondary-color;
$button-secondary-border-color: colors.$button-secondary-color;
$button-secondary-color-hover: colors.$button-secondary-color-hover;
$button-secondary-color-active: colors.$button-secondary-color-active;
$button-secondary-color-disabled: colors.$button-secondary-color-disabled;

$button-tertiary-background-color: colors.$button-tertiary-background-color;
$button-tertiary-background-color-hover: colors.$button-tertiary-background-color-hover;
$button-tertiary-background-color-active: colors.$button-tertiary-background-color-active;
$button-tertiary-color: colors.$button-tertiary-color;
$button-tertiary-color-hover: colors.$button-tertiary-color-hover;
$button-tertiary-color-active: colors.$button-tertiary-color-active;

// Color-system
// semaphore-based
$color-green: colors.$color-green;
$color-yellow: colors.$color-yellow;
$color-red: colors.$color-red;
$color-blue: colors.$color-blue;
$color-grey: colors.$color-grey;
$color-black: colors.$color-black;
$color-primary: $color-blue;
$color-secondary: $color-green;

// hovered-effects
$color-primary-hover: colors.$color-primary-hover;
$color-primary-active: colors.$color-primary-active;
$color-primary-disabled: colors.$color-primary-disabled;
$color-secondary-hover: colors.$color-secondary-hover;
$color-secondary-active: colors.$color-secondary-active;
$color-secondary-disabled: colors.$color-secondary-disabled;

$cancel-color: colors.$cancel-color;
$success-color: colors.$success-color;
$success-border-color: colors.$success-color;
$success-background-color: colors.$success-color;
$alert-color: colors.$color-yellow;
$alert-border-color: colors.$color-yellow;
$alert-background-color: colors.$color-yellow;
$remove-color: colors.$remove-color;
$remove-border-color: colors.$remove-color;
$remove-background-color: colors.$remove-color;

// App tagging coloring system
// Warning
$app-warning-background-color: colors.$app-warning-background-color;
$app-warning-color: colors.$app-warning-color;
$app-warning-background-color-hover: colors.$app-warning-background-color-hover;
$app-warning-background-color-blink: colors.$app-warning-background-color-blink;
$app-warning-color-hover: colors.$app-warning-color-hover;
// Cancel
$app-cancel-background-color: colors.$app-cancel-background-color;
$app-cancel-color: colors.$app-cancel-color;
$app-cancel-background-color-hover: colors.$app-cancel-background-color-hover;
$app-cancel-color-hover: colors.$app-cancel-color-hover;
// Success
$app-success-background-color: colors.$app-success-background-color;
$app-success-border-color: colors.$app-success-border-color;
$app-success-color: colors.$app-success-color;
$app-success-background-color-hover: colors.$app-success-background-color-hover;
$app-success-color-hover: colors.$app-success-color-hover;
// Duplicate
$app-duplicate-background-color: colors.$app-duplicate-background-color;
$app-duplicate-border-color: colors.$app-duplicate-border-color;
$app-duplicate-color: colors.$app-duplicate-color;
$app-duplicate-background-color-hover: colors.$app-duplicate-background-color-hover;
$app-duplicate-color-hover: colors.$app-duplicate-color-hover;
// Edit
$app-edit-background-color: colors.$app-edit-background-color;
$app-edit-border-color: colors.$app-edit-border-color;
$app-edit-color: colors.$app-edit-color;
$app-edit-background-color-hover: colors.$app-edit-background-color-hover;
$app-edit-color-hover: colors.$app-edit-color-hover;

// Dropdown definitions
$dropdown-background-color: colors.$dropdown-background-color;
$dropdown-selected-background-color: colors.$dropdown-selected-background-color;
$dropdown-background-color-disabled: colors.$dropdown-background-color-disabled;
$dropdown-background-color-hover: colors.$dropdown-background-color-hover;
$dropdown-background-color-active: colors.$dropdown-background-color-active;
$dropdown-color: colors.$dropdown-color;
$dropdown-border-color: colors.$dropdown-border-color;
$dropdown-color-hover: colors.$dropdown-color-hover;
$dropdown-color-active: colors.$dropdown-color-active;
$dropdown-border-radius: 4px;
$dropdown-border-width: 1px;
$dropdown-button-background-color: colors.$dropdown-button-background-color;
$dropdown-button-background-color-hover: colors.$dropdown-button-background-color-hover;
$dropdown-button-background-color-active: colors.$dropdown-button-background-color-active;

$chat-reporter-background-color: colors.$chat-reporter-background-color;

// Datalist definitions
$data-list-col-width-25: 25px;
$data-list-col-width-30: 30px;
$data-list-col-width-40: 40px;
$data-list-col-width-50: 50px;
$data-list-col-width-60: 60px;
$data-list-col-width-65: 65px;
$data-list-col-width-70: 70px;
$data-list-col-width-75: 75px;
$data-list-col-width-80: 80px;
$data-list-col-width-85: 85px;
$data-list-col-width-90: 90px;
$data-list-col-width-95: 95px;
$data-list-col-width-100: 100px;
$data-list-col-width-110: 110px;
$data-list-col-width-115: 115px;
$data-list-col-width-120: 120px;
$data-list-col-width-125: 125px;
$data-list-col-width-150: 150px;
$data-list-col-width-175: 175px;
$data-list-col-width-200: 200px;
$data-list-col-width-225: 225px;
$data-list-col-width-250: 250px;
$data-list-col-width-275: 275px;
$data-list-col-width-300: 300px;
$data-list-col-width-325: 325px;
$data-list-col-width-350: 350px;
$data-list-col-width-375: 375px;
$data-list-col-width-400: 400px;
$data-list-col-width-425: 425px;
$data-list-col-width-450: 450px;
$data-list-col-width-475: 475px;
$data-list-col-width-500: 500px;

$data-list-background-color: colors.$data-list-background-color;
$data-list-background-color-hover: colors.$data-list-background-color-hover;

/* Box Border definitions */
$box-border-hover: 1px solid #e9e9e9;

/* App Menu globals */
$app-menu-item-shadow: unset;
$app-menu-item-shadow-hover: 0px 10px 20px rgba(0, 0, 0, 0.1);

/* App Transition and Animation globals */
$app-transition-time: 200ms;
$app-transition-time-500: 500ms;

/* Input coloring configuration */
$input-border-width: 1px;
$input-border-color: colors.$input-border-color;
$input-border-color-hover: colors.$input-border-color-hover;
$input-border: $input-border-width solid $input-border-color;
$input-background-color: colors.$input-background-color;
$input-background-color-hover: colors.$input-background-color-hover;
$input-background-color-disabled: colors.$input-background-color-disabled;
$input-color: colors.$input-color;
$input-color-hover: colors.$input-color-hover;
$input-color-active: colors.$input-color-active;
$input-color-disabled: colors.$input-color-disabled;
$input-error-border-color: colors.$input-error-border-color;
$input-error-color: colors.$input-error-color;
$input-hint-color: colors.$input-hint-color;
$input-hint-background-color: colors.$input-hint-background-color;
$input-placeholder-color: colors.$input-placeholder-color;
$input-label-color: colors.$input-label-color;

// App version tag
$version-tag-color: colors.$version-tag-color;
$version-tag-background-color: colors.$version-tag-background-color;

/* Data List */
$data-list-checkbox-height: 16px;

/* Fluent UI overrides */
$side-panel-color: $app-body-color;
$side-panel-background-color: $app-body-background-color;

// Mail colors
$mail-title-color: colors.$mail-title-color;

/* Collapsible effects */
$collapsible-icon-color: colors.$collapsible-icon-color;
$collapsible-icon-color-hover: colors.$collapsible-icon-color-hover;

// Maps configurations
$maps-clickable-layer-background-color: colors.$maps-clickable-layer-background-color;
$maps-clickable-layer-background-color-hover: colors.$maps-clickable-layer-background-color-hover;
$maps-clickable-layer-color: colors.$maps-clickable-layer-color;
$maps-clickable-layer-color-hover: colors.$maps-clickable-layer-color-hover;

// Spacing
$spacing-small: 0.5rem;
$spacing-medium: 1rem;
$spacing-large: 1.5rem;

// Borders
$border-radius-small: 2px;
$border-radius-medium: 4px;
$border-radius-large: 8px;

// Typography
$font-size-small: 12px;
$font-size-medium: 14px;
$font-size-large: 16px;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Shadows
$shadow-small: 0 2px 4px rgba(0, 0, 0, 0.1);
$shadow-medium: 0 4px 8px rgba(0, 0, 0, 0.1);
$shadow-large: 0 8px 16px rgba(0, 0, 0, 0.1);

// Transitions
$transition-time-fast: 0.2s;
$transition-time-medium: 0.3s;
$transition-time-slow: 0.4s;
$transition-time-slower: 0.5s;
$transition-time-slowest: 0.6s;
$transition-effect: ease-in-out;
$transition-all-fast: all $transition-time-fast $transition-effect;
$transition-all-medium: all $transition-time-medium $transition-effect;
$transition-all-slow: all $transition-time-slow $transition-effect;
$transition-all-slower: all $transition-time-slower $transition-effect;
$transition-all-slowest: all $transition-time-slowest $transition-effect;
