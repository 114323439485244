@use "static/sass/theme" as theme;
@use "static/sass/base" as base;

.toast-container {
	position: fixed;
	right: 0;
	top: theme.$app-header-height;
	z-index: base.$z-index-top;
	max-width: 100%;
	padding: theme.$app-box-padding;
	pointer-events: none;
	display: flex;
	flex-direction: column;
	gap: theme.$app-box-margin;

	& > * {
		pointer-events: auto;
	}
}

@media screen and (max-width: theme.$breakpoint-width-mobile) {
	.toast-container {
		left: 0;
		right: 0;
		padding: calc(theme.$app-box-padding / 2);
	}
}
