@use "static/sass/theme" as theme;

// Fluent UI Icon styles

.icon {
	cursor: pointer;

	&-sm {
		font-size: 16px;
	}

	&-md {
		font-size: 24px;
	}

	&-lg {
		font-size: 32px;
	}

	&-primary {
		color: theme.$color-primary;
	}

	&-secondary {
		color: theme.$color-secondary;
	}

	&-green {
		color: theme.$color-green;
	}

	&-red {
		color: theme.$color-red;
	}

	&-blue {
		color: theme.$color-blue;
	}

	&-grey {
		color: theme.$color-grey;
	}
}
