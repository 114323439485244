@use "static/sass/theme" as theme;
@use "static/sass/inputs" as inputs;
@use "static/sass/formv2" as form;

.form_base,
.form-base {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: theme.$app-box-padding;

	&_title {
		flex: 1 0 auto;
		font-size: 2rem;
		z-index: 9996;
		font-weight: bold;
		color: white;
		background-color: rgba(18, 18, 18, 1);
		padding: theme.$app-box-padding;
	}

	div {
		flex: 1 0 auto;

		.form-columns-rows {
			flex: 1 0 auto;
			display: flex;
			flex-flow: column wrap;
			align-items: flex-start;
			justify-content: flex-start;
			margin-bottom: theme.$app-box-margin;
			margin-right: theme.$app-box-margin;

			.form-columns-row-item {
				flex: 1 0 auto;
				display: flex;
				flex-flow: row nowrap;
				margin-bottom: theme.$app-box-margin;
				margin-right: theme.$app-box-margin;

				& > * {
					flex: 1 0 auto;
					display: flex;
					margin-right: theme.$app-box-margin;

					&:last-child {
						margin-right: 0;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&.form-columns {
			flex: 1 0 auto;
			display: flex;
			flex-flow: column wrap;
			align-items: flex-start;
			justify-content: flex-start;

			.form-columns-add-item {
				button {
					flex: 0 0 auto;
					align-self: flex-end;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: theme.$app-box-margin;
					font-weight: theme.$font-weight-bolder;
					padding: calc(theme.$app-box-padding / 2)
						theme.$app-box-padding;

					&::after {
						margin-left: 5px;
						margin-right: 5px;
					}
				}
				// flex: 1 0 auto;
				// display: flex;
				// flex-flow: row nowrap;
				// margin-bottom: theme.$app-box-margin;
				// margin-right: theme.$app-box-margin;

				// & > * {
				// 	flex: 1 0 auto;
				// 	display: flex;
				// 	margin-right: theme.$app-box-margin;

				// 	&:last-child {
				// 		margin-right: 0;
				// 	}
				// }

				// &:last-child {
				// 	margin-right: 0;
				// }
			}

			// div {
			// 	flex: 0 1 auto;
			// 	display: flex;
			// 	flex-flow: column nowrap;
			// 	margin-bottom: theme.$app-box-margin;
			// 	margin-right: theme.$app-box-margin;

			// 	&:last-child {
			// 		margin-right: 0;
			// 	}

			// 	span.operator {
			// 		flex: 0 0 auto;
			// 		align-self: flex-end;
			// 		display: flex;
			// 		flex-flow: row nowrap;
			// 		align-items: center;
			// 		justify-content: flex-start;
			// 		margin-bottom: theme.$app-box-margin;
			// 		font-weight: 400;

			// 		&::after {
			// 			margin-left: 5px;
			// 			margin-right: 5px;
			// 		}
			// 	}
			// }
		}

		&.form-row {
			flex: 1 0 auto;
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;
			justify-content: flex-start;

			div {
				flex: 0 1 auto;
				display: flex;
				flex-flow: column nowrap;
				margin-bottom: theme.$app-box-margin;
				margin-right: theme.$app-box-margin;
				width: auto;

				&:last-child {
					margin-right: 0;
				}

				&.xs {
					min-width: inputs.$input-xs-width;
					width: inputs.$input-xs-width;
				}

				&.sm {
					min-width: inputs.$input-sm-width;
					width: inputs.$input-sm-width;
				}

				&.md {
					min-width: inputs.$input-md-width;
					width: inputs.$input-md-width;
				}

				&.lg {
					min-width: inputs.$input-lg-width;
					width: inputs.$input-lg-width;
				}

				&.xl {
					min-width: inputs.$input-xl-width;
					width: inputs.$input-xl-width;
				}

				span.operator {
					flex: 0 0 auto;
					align-self: flex-end;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: theme.$app-box-margin;
					font-weight: 400;

					&::after {
						margin-left: 5px;
						margin-right: 5px;
					}
				}
			}
		}

		label {
			font-size: 14px;
			font-weight: bold;
			margin: theme.$app-box-margin 0;
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			justify-self: stretch;

			// &::after {
			// 	margin-bottom: 5px;
			// }
		}

		input,
		textarea,
		select {
			padding: 5px;
			height: inputs.$input-form-height;
			margin-right: 5px;
			width: 100%;
			min-width: 100%;

			&.xs {
				min-width: inputs.$input-xs-width;
				width: inputs.$input-xs-width;
			}
			&.sm {
				min-width: inputs.$input-sm-width;
				width: inputs.$input-sm-width;
			}
			&.md {
				min-width: inputs.$input-md-width;
				width: inputs.$input-md-width;
			}
			&.lg {
				min-width: inputs.$input-lg-width;
				width: inputs.$input-lg-width;
			}
			&.xl {
				min-width: inputs.$input-xl-width;
				width: inputs.$input-xl-width;
			}

			&:disabled {
				cursor: not-allowed;
			}

			@media screen and (max-width: theme.$breakpoint-width-mobile) {
				&.xs {
					width: 50%;
				}

				&.sm {
					width: 80%;
				}

				&.md {
					width: 80%;
				}

				&.lg {
					width: 100%;
				}

				&.xl {
					width: 100%;
				}
			}
		}

		textarea {
			min-height: 80px;
		}

		input[disabled],
		input[type="checkbox"],
		input[type="radio"] {
			margin-right: 5px;
		}

		&:not(:first-child) {
			input[type="checkbox"],
			input[type="radio"] {
				margin-left: theme.$app-box-margin;
			}
		}

		& .content-view {
			font-weight: 600;
			line-height: 1em;
			border: 1px solid #ccc;
			border-radius: theme.$app-box-border-radius;
			padding: calc(theme.$app-box-padding / 2)
				calc(theme.$app-box-padding / 2);
			margin-bottom: calc(theme.$app-box-margin * 2);
		}

		&.dropdown-container {
			margin-top: calc(theme.$app-box-margin * 1.5);
			margin-bottom: calc(theme.$app-box-margin * 1.5);
		}

		&.form-controls {
			flex: 0 0 auto;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;
			align-items: center;
			margin-top: auto;

			button {
				flex: 0 0 auto;
			}

			button:not(:last-child) {
				margin-right: theme.$app-box-margin;
			}
		}
	}
}
