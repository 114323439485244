@use "static/sass/theme" as theme;

$input-hint-border-width: theme.$app-box-border-width;
$input-hint-font-size: 1.1em;
$input-hint-background-color: theme.$input-hint-background-color;

.input {
	&-hint {
		font-size: $input-hint-font-size;
		color: theme.$input-hint-color;
		margin-top: theme.$app-box-margin;
		padding: calc(0.5 * theme.$app-box-padding) theme.$app-box-padding;
		border: $input-hint-border-width solid theme.$app-box-border-color;
		border-radius: theme.$app-box-border-radius;
		background-color: $input-hint-background-color;
		flex: 1;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		width: auto;

		& > * {
			margin-right: theme.$app-box-margin;
		}

		.icon {
			margin-right: theme.$app-box-margin;
		}
	}
}
