@use "static/sass/theme" as theme;

$width: 100%;
$width-inline: 30px;
$height: 100%;
$height-inline: 30px;
$spinner-width: 60px;
$spinner-width-small: 30px;
$spinner-height: 60px;
$spinner-height-small: 30px;

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: $width;
	height: $height;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;

	&_inline,
	&-inline {
		position: unset;
		top: unset;
		left: unset;
		transform: none;
		width: $width-inline;
		height: $height-inline;
		z-index: 0;
		background-color: transparent;
		display: inline;
		justify-content: center;
		align-items: center;
	}

	&__spinner {
		border: 16px solid #f3f3f3;
		border-radius: 50%;
		border-top: 16px solid #3498db;
		-webkit-animation: spin 1s linear infinite;
		animation: spin 1s linear infinite;
		width: $spinner-width;
		height: $spinner-height;

		&.inline {
			border: 8px solid #f3f3f3;
			border-radius: 50%;
			border-top: 8px solid #3498db;
			-webkit-animation: spin 1s linear infinite;
			animation: spin 1s linear infinite;
			width: $spinner-width-small;
			height: $spinner-height-small;
		}

		&_small {
			width: 60px;
			height: 60px;
		}

		&_medium {
			width: 90px;
			height: 90px;
		}

		&_large {
			width: 120px;
			height: 120px;
		}

		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}

	&__text {
		color: white;
		font-size: 24px;
		font-weight: 500;
		margin-top: theme.$app-box-margin;
	}
}
