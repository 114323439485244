@use "static/sass/entry" as entry;

body,
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
