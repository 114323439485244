@use "static/sass/theme" as theme;

$company-selector-height-mobile: 30px;
$company-selector-height-tablet: 30px;
$company-selector-height-desktop: 28px;

.company-selector-container {
	position: relative;
	display: block;

	@media screen and (max-width: theme.$breakpoint-width-mobile-small) {
		& {
			display: none;
		}
	}

	.company-selector-button {
		display: flex;
		align-items: center;
		gap: 8px;
		height: $company-selector-height-mobile;
		padding: 0 calc(theme.$app-box-padding * 0.5);
		border: 2px solid theme.$app-box-border-color;
		border-radius: theme.$button-border-radius;
		background-color: rgba(255, 255, 255, 0.1);
		color: theme.$app-header-color;
		cursor: pointer;
		transition: all 0.2s ease;

		@media screen and (min-width: theme.$breakpoint-width-tablet) {
			& {
				height: $company-selector-height-tablet;
			}
		}

		@media screen and (min-width: theme.$breakpoint-width-desktop) {
			& {
				height: $company-selector-height-desktop;
			}
		}

		&:hover,
		&:focus,
		&:focus-visible,
		&:has(.company-option.active) {
			background-color: theme.$button-background-color-hover;
		}

		.company-name {
			font-weight: theme.$font-weight-bold;
			color: theme.$app-header-color;
			font-size: 1.2em;
		}

		i {
			font-size: 0.85em;
			color: theme.$app-header-color;
		}
	}

	.company-selector-dropdown {
		position: absolute;
		top: calc(100% + 2px);
		right: 0;
		min-width: 220px;
		background-color: theme.$app-body-background-color;
		border-radius: theme.$app-box-border-radius;
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
		z-index: theme.$app-header-z-index + 1;
		overflow: hidden;
		border: 1px solid theme.$app-box-border-color;

		.company-option {
			display: block;
			width: 100%;
			padding: theme.$app-box-padding;
			border: none;
			background: none;
			text-align: left;
			color: theme.$app-body-color;
			cursor: pointer;
			transition: all 0.2s ease;
			font-size: 1em;

			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
			}

			&.active {
				background-color: rgba(0, 0, 0, 0.1);
				color: theme.$color-secondary;
				font-weight: theme.$font-weight-bold;
			}
		}
	}
}
