@use "static/sass/theme" as theme;
@use "static/sass/inputs" as inputs;

// Positioning indexes
$z-index-bottom: 1;
$z-index-middle: 1000;
// $z-index-side-panel-layer: 989;
$z-index-side-panel-layer: 990;
$z-index-side-panel: 991;
$z-index-modal-base: 993;
$z-index-modal-layer: 994;
$z-index-modal: 995;
$z-index-modal-content: 996;
$z-index-top: 9999;

body,
html {
	height: 100%;
}

body {
	background-color: theme.$app-body-background-color;
	color: theme.$app-body-color;
	// font-family: 'Roboto', sans-serif;
	font-family: theme.$font-family !important;
	font-size: theme.$font-size;
	//line-height: theme.$font-line-height;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// #root {
// 	height: 100%;
// 	display: flex;
// 	flex-direction: column;
// }

/* Scroll-bars and related Definitions */

/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #646464 #dfe9eb;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
	height: 8px;
	width: 8px;
}
*::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
	background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
	background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: #646464;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: #626262;
}

*::-webkit-scrollbar-thumb:active {
	background-color: #616161;
}

select,
textarea,
input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="url"],
input[type="search"],
input[type="date"],
input[type="time"],
.base-input {
	border: inputs.$input-border;
	border-radius: inputs.$input-border-radius;
	padding: inputs.$input-padding;
	font-size: theme.$font-size;
	font-weight: theme.$font-weight;
	color: inputs.$input-color;
	background-color: inputs.$input-background-color;

	&:disabled {
		background-color: inputs.$input-background-color-disabled !important;
		cursor: not-allowed;
	}

	&:not(:disabled):hover {
		border-color: inputs.$input-border-color-hover;
		background-color: inputs.$input-background-color-hover;
	}

	&:not(:disabled):focus {
		border-color: inputs.$input-border-color-hover;
		background-color: inputs.$input-background-color-hover;
	}

	&.xs {
		min-width: inputs.$input-xs-width;
		width: inputs.$input-xs-width;
	}

	&.sm {
		min-width: inputs.$input-sm-width;
		width: inputs.$input-sm-width;
	}

	&.md {
		min-width: inputs.$input-md-width;
		width: inputs.$input-md-width;
	}

	&.lg {
		min-width: inputs.$input-lg-width;
		width: inputs.$input-lg-width;
	}

	&.xl {
		min-width: inputs.$input-xl-width;
		width: inputs.$input-xl-width;
		margin-right: 0;
	}

	@media screen and (max-width: theme.$breakpoint-width-mobile) {
		&.xs {
			width: 50%;
		}

		&.sm {
			width: 80%;
		}

		&.md {
			width: 80%;
		}

		&.lg {
			width: 100%;
			margin-right: 0;
		}

		&.xl {
			width: 100%;
			margin-right: 0;
		}
	}
}

button,
input[type="button"] {
	cursor: pointer;
}

button:disabled,
input[type="button"]:disabled {
	cursor: not-allowed;
}

.button {
	padding: calc(theme.$button-padding / 2) theme.$button-padding;

	&.icon {
		padding: calc(theme.$button-padding / 2) !important;
		height: 32px;
		width: 32px;
	}
}

.bold-text {
	font-weight: theme.$font-weight-bold;
}

.bolder-text {
	font-weight: theme.$font-weight-bolder;
}

.margin-left-auto {
	margin-left: auto;
}

.spacer-1 {
	// flex: 1;
	height: 1em !important;
}

.spacer-2 {
	// flex: 1;
	height: 2em !important;
}

textarea,
select,
input,
div[role="combobox"] {
	font-family: theme.$font-family !important;
}

/* FluentUI overrides */

#fluent-default-layer-host {
	z-index: $z-index-modal-content !important;
}

.ms-Layer {
	z-index: $z-index-side-panel-layer;
}

.ms-ComboBox-container {
	::after {
		border: 0;
	}

	.ms-ComboBox {
		padding-left: 0;

		& input.ms-ComboBox-Input {
			margin-top: 0;
			margin-bottom: 0;
			margin-right: 0;
			margin-left: 0;
		}
	}

	&.xs {
		width: inputs.$input-xs-width;
	}

	&.sm {
		width: inputs.$input-sm-width;
	}

	&.md {
		width: inputs.$input-md-width;
	}

	&.lg {
		width: inputs.$input-lg-width;
	}

	&.xl {
		width: inputs.$input-xl-width;
	}

	@media screen and (max-width: theme.$breakpoint-width-mobile) {
		&.xs {
			width: 50%;
		}

		&.sm {
			width: 80%;
		}

		&.md {
			width: 80%;
		}

		&.lg {
			width: 100%;
		}

		&.xl {
			width: 100%;
		}
	}
}

.ms-DatePicker {
	.ms-TextField-fieldGroup {
		border: inputs.$input-border-width solid inputs.$input-border-color;
		border-radius: inputs.$input-border-radius;
	}

	input[role="combobox"] {
		margin-right: 0 !important;
		margin-bottom: 0;
		margin-top: 0;
	}

	&.xs {
		width: inputs.$input-xs-width;
	}

	&.sm {
		width: inputs.$input-sm-width;
	}

	&.md {
		width: inputs.$input-md-width;
	}

	&.lg {
		width: inputs.$input-lg-width;
	}

	&.xl {
		width: inputs.$input-xl-width;
	}

	@media screen and (max-width: theme.$breakpoint-width-mobile) {
		&.xs {
			width: 50%;
		}

		&.sm {
			width: 80%;
		}

		&.md {
			width: 80%;
		}

		&.lg {
			width: 100%;
		}

		&.xl {
			width: 100%;
		}
	}
}

.side-panel {
	.ms-Panel-commands,
	.ms-Panel-main {
		.ms-Panel-header {
			& div[role="heading"] {
				color: theme.$side-panel-color !important;
			}
		}

		color: theme.$side-panel-color;
		background-color: theme.$side-panel-background-color;
	}
}

.dropdown-container .ms-Dropdown .ms-Dropdown-title {
	color: theme.$dropdown-color;
	background-color: theme.$dropdown-background-color !important;
}

.ms-ComboBox-container {
	height: inputs.$input-form-height !important;

	.ms-ComboBox {
		height: inputs.$input-form-height !important;

		input {
			padding-top: calc(inputs.$input-padding * 0.35);
			height: 100%;
			border: none;
			background-color: none;
		}

		.ms-Button {
			height: inputs.$input-form-height;
			width: inputs.$input-form-height;
		}
	}
}

.hide-mobile {
	@media screen and (max-width: theme.$breakpoint-width-mobile) {
		& {
			display: none;
		}
	}
}
