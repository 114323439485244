@use "static/sass/theme" as theme;

/* Text-alignments */
.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.text-justify {
	text-align: justify !important;
}

/* General alignments */
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* TODO: Font-sizes */
