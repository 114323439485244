// @mixin dark-mode {
// 	// Basic Body color definitions
// 	// Dark theme
// 	$app-body-background-color: #000;
// 	$app-body-color: #fff;

// 	// Basic Components color definitions
// 	// Dark theme
// 	$app-title-color: rgba(255, 255, 255, 0.65);
// 	$app-title-color-hover: rgba(255, 255, 255, 1);

// 	// Box System color definitions
// 	// Dark theme
// 	$app-box-border-color: rgb(186, 186, 186);
// 	$app-box-border-color-hover: rgba(230, 230, 230, 1);
// 	$app-box-border-color-active: rgba(230, 230, 230, 1);

// 	// Box shadow definitions
// 	$box-shadow-color: rgba(0, 0, 0, 0.3);
// 	$box-shadow-color-hover: rgba(0, 0, 0, 0.4);

// 	// Header color definitions
// 	// Dark theme
// 	$app-header-color: #fff;
// 	$app-header-color-hover: $app-title-color;
// 	$app-header-border-color: rgba(255, 255, 255, 0.65);
// 	$app-header-title-color: #fff;
// 	$app-header-title-color-hover: lighten($app-header-title-color, 10%);
// 	$app-header-background-color: #000;

// 	// Drawer color definitions
// 	// Dark theme
// 	$app-drawer-background-color: #000;
// 	$app-drawer-background-color-hover: #000;
// 	$app-drawer-color: #fff;
// 	$app-drawer-border-color: rgba(200, 200, 200, 0.55);

// 	// Button color definitions
// 	// Dark theme
// 	$button-border-color: rgba(200, 200, 200, 0.55);
// 	$button-border-color-hover: rgba(200, 200, 200, 0.8);
// 	$button-background-color: #000;
// 	$button-background-color-hover: #323232;
// 	$button-background-color-active: #323232;
// 	$button-color-disabled: rgba(220, 220, 220, 0.55);
// 	$button-color: rgba(200, 200, 200, 0.9);
// 	$button-color-hover: rgba(200, 200, 200, 1);
// 	$button-focus-outline-color: rgba(200, 200, 200, 0.75);

// 	// Button presets by priority
// 	// Default: Light theme
// 	// Dark theme
// 	$button-primary-background-color: #0078d4;
// 	$button-primary-background-color-hover: #0063b1;
// 	$button-primary-background-color-active: #005a9e;
// 	$button-primary-color: #fff;
// 	$button-primary-color-hover: #fff;
// 	$button-primary-color-active: #fff;
// 	$button-primary-color-disabled: #fff;
// 	$button-primary-background-color-disabled: #6c757d;

// 	// Dark theme
// 	$button-secondary-background-color: rgba(0, 0, 0, 0.9);
// 	$button-secondary-background-color-hover: rgba(45, 45, 45, 1);
// 	$button-secondary-background-color-active: rgba(45, 45, 45, 1);
// 	$button-secondary-color: #f5f5f5;
// 	$button-secondary-color-hover: #ffffff;
// 	$button-secondary-color-active: #ffffff;
// 	$button-secondary-color-disabled: #6c757d;

// 	// Dark theme
// 	$button-tertiary-background-color: transparent;
// 	$button-tertiary-background-color-hover: rgba(0, 0, 0, 0.05);
// 	$button-tertiary-background-color-active: rgba(0, 0, 0, 0.1);
// 	$button-tertiary-color: rgba(200, 200, 200, 0.9);
// 	$button-tertiary-color-hover: rgba(200, 200, 200, 1);
// 	$button-tertiary-color-active: rgba(200, 200, 200, 1);

// 	// System Alerting and Alarming color system
// 	// semaphore-based
// 	$color-green: #006f2e;
// 	$color-yellow: #f69d0d;
// 	$color-red: #8c1a00;
// 	$color-blue: #0078d4;
// 	$color-grey: #f5f5f5;
// 	$color-black: #000;
// 	$color-primary: $color-blue;
// 	$color-secondary: $color-grey;

// 	// Dark theme
// 	$cancel-color: #ce2c08;
// 	$success-color: #0ba049;
// 	$alert-color: #f69d0d;
// 	$remove-color: #f06a0c;

// 	// App tagging coloring system
// 	// Default: Light theme
// 	// Dark theme
// 	$app-warning-background-color: #f69d0d;
// 	$app-warning-color: #000;
// 	$app-warning-background-color-hover: lighten(#f69d0d, 5%);
// 	$app-warning-background-color-blink: lighten(#f69d0d, 20%);
// 	$app-warning-color-hover: #000;
// 	$app-cancel-background-color: #8c1a00;
// 	$app-cancel-color: #fff;
// 	$app-cancel-background-color-hover: lighten(#8c1a00, 5%);
// 	$app-cancel-color-hover: #fff;
// 	$app-success-background-color: #006f2e;
// 	$app-success-color: #fff;
// 	$app-success-background-color-hover: lighten(#006f2e, 5%);
// 	$app-success-color-hover: #fff;

// 	// Dropdown color definitions
// 	// Dark theme
// 	$dropdown-background-color: $app-body-background-color;
// 	$dropdown-selected-background-color: transparent;
// 	$dropdown-background-color-hover: darken($dropdown-background-color, 7.5%);
// 	$dropdown-background-color-active: darken($dropdown-background-color, 10%);
// 	$dropdown-color: rgba(200, 200, 200, 0.8);
// 	$dropdown-border-color: rgba(200, 200, 200, 0.8);
// 	$dropdown-color-hover: rgba(200, 200, 200, 0.9);
// 	$dropdown-color-active: rgba(200, 200, 200, 0.9);
// 	$dropdown-button-background-color: transparent;
// 	$dropdown-button-background-color-hover: #202020;
// 	$dropdown-button-background-color-active: #202020;

// 	// Datalist color definitions
// 	// Dark theme
// 	$data-list-background-color: #000000;
// 	$data-list-background-color-hover: #212121;

// 	/* Input coloring configuration */
// 	// Dark theme
// 	$input-border-color: rgba(190, 190, 190, 1);
// 	$input-border-color-hover: rgba(200, 200, 200, 1);
// 	$input-background-color: transparent;
// 	$input-background-color-hover: $app-body-background-color;
// 	$input-background-color-disabled: #2e2e2e;
// 	$input-color: rgba(210, 210, 210, 1);
// 	$input-color-hover: rgba(245, 245, 245, 1);
// 	$input-color-active: rgba(255, 255, 255, 1);

// 	/* System Version tag coloring */
// 	// Dark theme
// 	$version-tag-color: #b5b5b5;
// 	$version-tag-background-color: transparent;

// 	/* Fluent UI color overrides */
// 	// Dark theme
// 	$side-panel-color: $app-body-color;
// 	$side-panel-background-color: $app-body-background-color;
// }

// // @mixin light-mode {
// // Basic Body color definitions
// // Light theme
// $app-body-background-color: #fff;
// $app-body-color: #000;

// // Basic Components color definitions
// // Light theme
// $app-title-color: rgba(0, 0, 0, 0.8);
// $app-title-color-hover: rgba(0, 0, 0, 0.55);

// // Box System color definitions
// // Light theme
// $app-box-border-color: rgb(90, 90, 90);
// $app-box-border-color-hover: rgb(32, 32, 32);
// $app-box-border-color-active: rgb(32, 32, 32);

// // Box shadow definitions
// $box-shadow-color: rgba(0, 0, 0, 0.3);
// $box-shadow-color-hover: rgba(0, 0, 0, 0.4);

// // Header color definitions
// // Default: Light theme
// $app-header-color: #000;
// $app-header-color-hover: $app-title-color;
// $app-header-border-color: rgba(0, 0, 0, 0.3);
// $app-header-title-color: #000;
// $app-header-title-color-hover: lighten($app-header-title-color, 10%);
// $app-header-background-color: #fff;

// // Drawer color definitions
// // Default: Light theme
// $app-drawer-background-color: #fff;
// $app-drawer-background-color-hover: #fff;
// $app-drawer-color: #000;
// $app-drawer-border-color: rgba(0, 0, 0, 0.3);

// // Button color definitions
// // Default: Light theme
// $button-border-color: rgba(0, 0, 0, 0.2);
// $button-border-color-hover: rgba(0, 0, 0, 0.4);
// $button-background-color: #fff;
// $button-background-color-hover: #f5f5f5;
// $button-background-color-active: #f5f5f5;
// $button-color-disabled: rgba(0, 0, 0, 0.4);
// $button-color: rgba(0, 0, 0, 0.8);
// $button-color-hover: rgba(0, 0, 0, 0.8);
// $button-focus-outline-color: rgba(0, 0, 0, 0.4);

// // Button presets by priority
// // Default: Light theme
// // Dark theme
// $button-primary-background-color: #0078d4;
// $button-primary-background-color-hover: #0063b1;
// $button-primary-background-color-active: #005a9e;
// $button-primary-color: #fff;
// $button-primary-color-hover: #fff;
// $button-primary-color-active: #fff;
// $button-primary-color-disabled: #fff;
// $button-primary-background-color-disabled: #6c757d;

// // Default: Light theme
// $button-secondary-background-color: #fff;
// $button-secondary-background-color-hover: #f5f5f5;
// $button-secondary-background-color-active: #f5f5f5;
// $button-secondary-color: rgba(0, 0, 0, 0.9);
// $button-secondary-color-hover: rgba(0, 0, 0, 0.9);
// $button-secondary-color-active: rgba(0, 0, 0, 0.9);
// $button-secondary-color-disabled: #6c757d;

// // Default: Light theme
// $button-tertiary-background-color: transparent;
// $button-tertiary-background-color-hover: rgba(0, 0, 0, 0.05);
// $button-tertiary-background-color-active: rgba(0, 0, 0, 0.1);
// $button-tertiary-color: rgba(0, 0, 0, 0.8);
// $button-tertiary-color-hover: rgba(0, 0, 0, 0.8);
// $button-tertiary-color-active: rgba(0, 0, 0, 0.8);

// // System Alerting and Alarming color system
// // semaphore-based
// $color-green: #006f2e;
// $color-yellow: #f69d0d;
// $color-red: #8c1a00;
// $color-blue: #0078d4;
// $color-grey: #f5f5f5;
// $color-black: #000;
// $color-primary: $color-blue;
// $color-secondary: $color-grey;

// // Default: Light theme
// $cancel-color: #8c1a00;
// $success-color: #006f2e;
// $alert-color: #f69d0d;
// $remove-color: #f06a0c;

// // App tagging coloring system
// // Default: Light theme
// // Dark theme
// $app-warning-background-color: #f69d0d;
// $app-warning-color: #000;
// $app-warning-background-color-hover: lighten(#f69d0d, 5%);
// $app-warning-background-color-blink: lighten(#f69d0d, 20%);
// $app-warning-color-hover: #000;
// $app-cancel-background-color: #8c1a00;
// $app-cancel-color: #fff;
// $app-cancel-background-color-hover: lighten(#8c1a00, 5%);
// $app-cancel-color-hover: #fff;
// $app-success-background-color: #006f2e;
// $app-success-color: #fff;
// $app-success-background-color-hover: lighten(#006f2e, 5%);
// $app-success-color-hover: #fff;

// // Dropdown color definitions
// // Default: Light theme
// $dropdown-background-color: #fff;
// $dropdown-selected-background-color: transparent;
// $dropdown-background-color-hover: darken($dropdown-background-color, 7.5%);
// $dropdown-background-color-active: darken($dropdown-background-color, 10%);
// $dropdown-color: rgba(0, 0, 0, 0.8);
// $dropdown-border-color: rgba(0, 0, 0, 0.8);
// $dropdown-color-hover: rgba(0, 0, 0, 0.8);
// $dropdown-color-active: rgba(0, 0, 0, 0.8);
// $dropdown-button-background-color: #f9f9f9;
// $dropdown-button-background-color-hover: #e4e4e4;
// $dropdown-button-background-color-active: #e4e4e4;

// // Datalist color definitions
// // Default: Light theme
// $data-list-background-color: #f7f7f7;
// $data-list-background-color-hover: #e3e3e3;

// /* Input coloring configuration */
// // Default: Light theme
// $input-border-color: rgba(0, 0, 0, 0.2);
// $input-border-color-hover: rgba(0, 0, 0, 0.4);
// $input-background-color: #fafafa;
// $input-background-color-hover: #ffffff;
// $input-background-color-disabled: #eee;
// $input-color: rgba(20, 20, 20, 1);
// $input-color-hover: rgba(0, 0, 0, 0.8);
// $input-color-active: rgba(0, 0, 0, 1);

// /* System Version tag coloring */
// // Default: Light theme
// $version-tag-color: #2b2b2b;
// $version-tag-background-color: #c3c2c2;

// /* Fluent UI color overrides */
// // Default: Light theme
// $side-panel-color: $app-body-color;
// $side-panel-background-color: $app-body-background-color;
// // }

// Basic Body color definitions
// Light theme
$app-body-background-color: #fff;
$app-body-color: #000;
// Dark theme
@media (prefers-color-scheme: dark) {
	$app-body-background-color: #000;
	$app-body-color: #fff;
}

// Basic Components color definitions
// Light theme
$app-title-color: rgba(0, 0, 0, 0.8);
$app-title-color-hover: rgba(0, 0, 0, 0.55);
// Dark theme
@media (prefers-color-scheme: dark) {
	$app-title-color: rgba(255, 255, 255, 0.65);
	$app-title-color-hover: rgba(255, 255, 255, 1);
}

// Box System color definitions
// Light theme
$app-box-border-color: rgb(90, 90, 90);
$app-box-border-color-light: rgba(110, 110, 110, 0.5);
$app-box-border-color-hover: rgb(32, 32, 32);
$app-box-border-color-active: rgb(32, 32, 32);
// Dark theme
@media (prefers-color-scheme: dark) {
	$app-box-border-color: rgb(186, 186, 186);
	$app-box-border-color-light: rgba(186, 186, 186, 0.5);
	$app-box-border-color-hover: rgba(230, 230, 230, 1);
	$app-box-border-color-active: rgba(230, 230, 230, 1);
}

// Box shadow definitions
$box-shadow-color: rgba(0, 0, 0, 0.3);
$box-shadow-color-hover: rgba(0, 0, 0, 0.4);

// Header color definitions
// Default: Light theme
$app-header-color: #000;
$app-header-color-hover: $app-title-color;
$app-header-border-color: rgba(0, 0, 0, 0.3);
$app-header-title-color: #000;
$app-header-title-color-hover: lighten($app-header-title-color, 10%);
$app-header-background-color: #fff;
// Dark theme
@media (prefers-color-scheme: dark) {
	$app-header-color: #fff;
	$app-header-color-hover: $app-title-color;
	$app-header-border-color: rgba(255, 255, 255, 0.65);
	$app-header-title-color: #fff;
	$app-header-title-color-hover: lighten($app-header-title-color, 10%);
	$app-header-background-color: #000;
}

// Drawer color definitions
// Default: Light theme
$app-drawer-background-color: #fff;
$app-drawer-background-color-hover: #fff;
$app-drawer-color: #000;
$app-drawer-border-color: rgba(0, 0, 0, 0.3);
// Dark theme
@media (prefers-color-scheme: dark) {
	$app-drawer-background-color: #000;
	$app-drawer-background-color-hover: #000;
	$app-drawer-color: #fff;
	$app-drawer-border-color: rgba(200, 200, 200, 0.55);
}

// Overlay div definitions
// Default: Light theme
$app-overlay-background-color: rgba(0, 0, 0, 0.5);
// Dark theme
@media (prefers-color-scheme: dark) {
	$app-overlay-background-color: rgba(142, 142, 142, 0.115);
}

// Button color definitions
// Default: Light theme
$button-border-color: rgba(0, 0, 0, 0.2);
$button-border-color-hover: rgba(0, 0, 0, 0.4);
$button-background-color: #fff;
$button-background-color-hover: #f5f5f5;
$button-background-color-active: #f5f5f5;
$button-color-disabled: rgba(0, 0, 0, 0.4);
$button-color: rgba(0, 0, 0, 0.8);
$button-color-hover: rgba(0, 0, 0, 0.8);
$button-focus-outline-color: rgba(0, 0, 0, 0.4);
// Dark theme
@media (prefers-color-scheme: dark) {
	$button-border-color: rgba(200, 200, 200, 0.55);
	$button-border-color-hover: rgba(200, 200, 200, 0.8);
	$button-background-color: #000;
	$button-background-color-hover: #323232;
	$button-background-color-active: #323232;
	$button-color-disabled: rgba(220, 220, 220, 0.55);
	$button-color: rgba(200, 200, 200, 0.9);
	$button-color-hover: rgba(200, 200, 200, 1);
	$button-focus-outline-color: rgba(200, 200, 200, 0.75);
}

// Button presets by priority
// Default: Light theme
// Dark theme
$button-primary-background-color: #0078d4;
$button-primary-background-color-hover: #0063b1;
$button-primary-background-color-active: #005a9e;
$button-primary-color: #fff;
$button-primary-color-hover: #fff;
$button-primary-color-active: #fff;
$button-primary-color-disabled: #fff;
$button-primary-background-color-disabled: #6c757d;

// Default: Light theme
$button-secondary-background-color: #fff;
$button-secondary-background-color-hover: #f5f5f5;
$button-secondary-background-color-active: #f5f5f5;
$button-secondary-color: rgba(0, 0, 0, 0.9);
$button-secondary-color-hover: rgba(0, 0, 0, 0.9);
$button-secondary-color-active: rgba(0, 0, 0, 0.9);
$button-secondary-color-disabled: #6c757d;
// Dark theme
@media (prefers-color-scheme: dark) {
	$button-secondary-background-color: rgba(0, 0, 0, 0.9);
	$button-secondary-background-color-hover: rgba(45, 45, 45, 1);
	$button-secondary-background-color-active: rgba(45, 45, 45, 1);
	$button-secondary-color: #f5f5f5;
	$button-secondary-color-hover: #ffffff;
	$button-secondary-color-active: #ffffff;
	$button-secondary-color-disabled: #6c757d;
}

// Default: Light theme
$button-tertiary-background-color: transparent;
$button-tertiary-background-color-hover: rgba(0, 0, 0, 0.05);
$button-tertiary-background-color-active: rgba(0, 0, 0, 0.1);
$button-tertiary-color: rgba(0, 0, 0, 0.8);
$button-tertiary-color-hover: rgba(0, 0, 0, 0.8);
$button-tertiary-color-active: rgba(0, 0, 0, 0.8);
// Dark theme
@media (prefers-color-scheme: dark) {
	$button-tertiary-background-color: transparent;
	$button-tertiary-background-color-hover: rgba(0, 0, 0, 0.05);
	$button-tertiary-background-color-active: rgba(0, 0, 0, 0.1);
	$button-tertiary-color: rgba(200, 200, 200, 0.9);
	$button-tertiary-color-hover: rgba(200, 200, 200, 1);
	$button-tertiary-color-active: rgba(200, 200, 200, 1);
}

// System Alerting and Alarming color system
// semaphore-based
$color-green: #006f2e;
$color-yellow: #f69d0d;
$color-red: #8c1a00;
$color-blue: #0078d4;
$color-grey: #f5f5f5;
$color-black: #000;
$color-primary: $color-blue;
$color-secondary: $color-grey;
// hovered-effects
$color-primary-hover: lighten($color-primary, 10%);
$color-primary-active: lighten($color-primary, 10%);
$color-primary-disabled: darken($color-primary, 20%);
$color-secondary-hover: lighten($color-secondary, 10%);
$color-secondary-active: lighten($color-secondary, 10%);
$color-secondary-disabled: darken($color-secondary, 20%);

// Default: Light theme
$cancel-color: #8c1a00;
$success-color: #006f2e;
$alert-color: #f69d0d;
$remove-color: #f06a0c;
// Dark theme
@media (prefers-color-scheme: dark) {
	$cancel-color: #ce2c08;
	$success-color: #0ba049;
	$alert-color: #f69d0d;
	$remove-color: #f06a0c;
}

// App tagging coloring system
// Default: Light theme
// Dark theme
// Warning
$app-warning-background-color: #f69d0d;
$app-warning-color: #000;
$app-warning-background-color-hover: lighten(#f69d0d, 5%);
$app-warning-background-color-blink: lighten(#f69d0d, 20%);
$app-warning-color-hover: #000;
// Cancel
$app-cancel-background-color: #fff;
$app-cancel-border-color: #8c1a00;
$app-cancel-color: #8c1a00;
$app-cancel-background-color-hover: lighten(#8c1a00, 5%);
$app-cancel-color-hover: #fff;
// Success
$app-success-background-color: #006f2e;
$app-success-border-color: #006f2e;
$app-success-color: #fff;
$app-success-background-color-hover: lighten(#a6d1b8, 5%);
$app-success-color-hover: #006f2e;
// Duplicate
$app-duplicate-background-color: #967620;
$app-duplicate-border-color: #967620;
$app-duplicate-color: #fff;
$app-duplicate-background-color-hover: lighten(#967620, 5%);
$app-duplicate-color-hover: #967620;
// Edit
$app-edit-background-color: #0078d4;
$app-edit-border-color: #0078d4;
// $app-edit-border-color: #fff;
$app-edit-color: #fff;
$app-edit-background-color-hover: #fff;
$app-edit-color-hover: #0078d4;

/* Input coloring configuration */
// Default: Light theme
$input-border-color: rgba(0, 0, 0, 0.2);
$input-border-color-hover: rgba(0, 0, 0, 0.4);
$input-background-color: #fafafa;
$input-background-color-hover: #ffffff;
$input-background-color-disabled: #eee;
$input-color: rgba(20, 20, 20, 1);
$input-color-hover: rgba(0, 0, 0, 0.8);
$input-color-active: rgba(0, 0, 0, 1);
$input-error-border-color: rgba(79, 11, 15, 1);
$input-error-color: rgba(79, 11, 15, 1);
$input-hint-color: rgba(0, 0, 0, 0.7);
$input-hint-background-color: #fff6e3;
$input-placeholder-color: rgba(0, 0, 0, 0.5);
$input-color-disabled: rgba(0, 0, 0, 0.4);
$input-label-color: rgba(0, 0, 0, 0.8);
// Dark theme
@media (prefers-color-scheme: dark) {
	$input-border-color: rgba(190, 190, 190, 1);
	$input-border-color-hover: rgba(200, 200, 200, 1);
	$input-background-color: transparent;
	$input-background-color-hover: $app-body-background-color;
	$input-background-color-disabled: #2e2e2e;
	$input-color: rgba(210, 210, 210, 1);
	$input-color-hover: rgba(245, 245, 245, 1);
	$input-color-active: rgba(255, 255, 255, 1);
	$input-error-border-color: rgba(79, 11, 15, 1);
	$input-error-color: rgba(79, 11, 15, 1);
	$input-hint-color: rgba(200, 200, 200, 0.8);
	$input-hint-background-color: #000;
	$input-placeholder-color: rgba(200, 200, 200, 0.5);
	$input-color-disabled: rgba(200, 200, 200, 0.5);
	$input-label-color: rgba(200, 200, 200, 0.8);
}

// Dropdown color definitions
// Default: Light theme
$dropdown-background-color: #fff;
$dropdown-selected-background-color: transparent;
$dropdown-background-color-disabled: $input-background-color-disabled;
$dropdown-background-color-hover: darken($dropdown-background-color, 7.5%);
$dropdown-background-color-active: darken($dropdown-background-color, 10%);
$dropdown-color: rgba(0, 0, 0, 0.8);
$dropdown-border-color: rgba(0, 0, 0, 0.8);
$dropdown-color-hover: rgba(0, 0, 0, 0.8);
$dropdown-color-active: rgba(0, 0, 0, 0.8);
$dropdown-button-background-color: #f9f9f9;
$dropdown-button-background-color-hover: #e4e4e4;
$dropdown-button-background-color-active: #e4e4e4;
// Dark theme
@media (prefers-color-scheme: dark) {
	$dropdown-background-color: $app-body-background-color;
	$dropdown-background-color-disabled: $input-background-color-disabled;
	$dropdown-selected-background-color: transparent;
	$dropdown-background-color-hover: darken($dropdown-background-color, 7.5%);
	$dropdown-background-color-active: darken($dropdown-background-color, 10%);
	$dropdown-color: rgba(200, 200, 200, 0.8);
	$dropdown-border-color: rgba(200, 200, 200, 0.8);
	$dropdown-color-hover: rgba(200, 200, 200, 0.9);
	$dropdown-color-active: rgba(200, 200, 200, 0.9);
	$dropdown-button-background-color: transparent;
	$dropdown-button-background-color-hover: #202020;
	$dropdown-button-background-color-active: #202020;
}

// Datalist color definitions
// Default: Light theme
$data-list-background-color: #f7f7f7;
$data-list-background-color-hover: #e3e3e3;
// Dark theme
@media (prefers-color-scheme: dark) {
	$data-list-background-color: #000000;
	$data-list-background-color-hover: #212121;
}

/* System Version tag coloring */
// Default: Light theme
$version-tag-color: #2b2b2b;
$version-tag-background-color: #c3c2c2;
// Dark theme
@media (prefers-color-scheme: dark) {
	$version-tag-color: #b5b5b5;
	$version-tag-background-color: transparent;
}

/* Fluent UI color overrides */
// Default: Light theme
$side-panel-color: $app-body-color;
$side-panel-background-color: $app-body-background-color;
// Dark theme
@media (prefers-color-scheme: dark) {
	$side-panel-color: $app-body-color;
	$side-panel-background-color: $app-body-background-color;
}

// // Light theme
// @media (prefers-color-scheme: light) {
// 	@include light-mode;
// }
// Dark theme
// @media (prefers-color-scheme: dark) {
// 	// @include dark-mode;
// }

// Mail colors
$mail-title-color: rgba(158, 98, 25, 1);

$collapsible-icon-color: $app-title-color;
$collapsible-icon-color-hover: rgba(158, 98, 25, 1);

// Maps colorings
$maps-clickable-layer-background-color: rgba(0, 0, 0, 0);
$maps-clickable-layer-background-color-hover: rgba(0, 0, 0, 0.3);
$maps-clickable-layer-color: rgba(255, 255, 255, 0);
$maps-clickable-layer-color-hover: rgba(255, 255, 255, 1);
@media (prefers-color-scheme: dark) {
	$maps-clickable-layer-background-color: rgba(255, 255, 255, 0);
	$maps-clickable-layer-background-color-hover: rgba(255, 255, 255, 0.3);
	$maps-clickable-layer-color: rgba(255, 255, 255, 0);
	$maps-clickable-layer-color-hover: rgba(255, 255, 255, 1);
}

// Base colors
$white: #ffffff;
$black: #000000;

// Theme colors
$theme-primary: #0078d4;
$theme-primary-light: #2b88d8;
$theme-primary-dark: #005a9e;

// Text colors
$text-primary: #323130;
$text-secondary: #605e5c;

// Neutral colors
$neutral-lighter: #f3f2f1;
$neutral-light: #edebe9;
$neutral-primary: #323130;

// Status colors
$status-success: #107c10;
$status-success-background: #dff6dd;
$status-warning: #797673;
$status-warning-background: #fff4ce;
$status-error: #a4262c;
$status-error-background: #fde7e9;

// Dark mode colors
$color-background-dark: #121212;
$color-text-dark: #e0e0e0;
// Add other dark mode colors as needed
// Media query for dark mode
@media (prefers-color-scheme: dark) {
	$color-background: $color-background-dark;
	$color-text: $color-text-dark;
	// Override other color variables for dark mode
}

$chat-reporter-background-color: #fae67f;
@media (prefers-color-scheme: dark) {
	$chat-reporter-background-color: #fae67f;
}
