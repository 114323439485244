@use "static/sass/theme" as theme;

.userProfileForm {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: stretch;
	height: 100%;

	& > * {
		flex: 0 0 auto;
	}

	.section {
		margin-bottom: calc(2 * theme.$app-box-margin);

		h3.section_title {
			font-size: 2.2em;
			font-weight: 500;
			margin-bottom: theme.$app-box-margin;
		}

		form {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-items: flex-start;

			* {
				flex: 0 0 auto;
				// margin-bottom: theme.$app-box-margin;
			}

			.field-group {
				display: flex;
				flex-flow: column nowrap;
				align-items: flex-start;
				justify-content: flex-start;
				margin-bottom: theme.$app-box-margin;

				.field-row {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					width: 100%;

					& > *:not(:last-child) {
						margin-right: theme.$app-box-margin;
					}

					.field-group-value {
						flex: 1;
					}

					.icon-button {
						margin-left: theme.$app-box-margin;
						padding: 0;
						background: none;
						border: none;
						cursor: pointer;
						display: flex;
						align-items: center;
					}
				}

				label {
					font-size: 1.2em;
					font-weight: 600;
					margin-bottom: 5px;
				}

				input {
					margin-right: 0;
				}
			}
		}
	}

	.footer {
		// position: fixed;
		// bottom: 0;
		// width: 100%;
		justify-self: flex-end;
		background-color: theme.$app-body-background-color;
		// margin: theme.$app-box-margin;
		margin-top: auto;
		display: flex;
		justify-content: flex-end;
		// box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);

		& > * {
			flex: 0 0 auto;

			&:not(:last-child) {
				margin-right: theme.$app-box-margin;
			}
		}
	}

	@media (max-width: theme.$breakpoint-width-mobile) {
		.section {
			h3.section_title {
				font-size: 1.8em;
			}

			form {
				.field-group {
					label {
						font-size: 1em;
					}
				}
			}
		}
	}

	@media (max-width: theme.$breakpoint-width-mobile-small) {
		.section {
			h3.section_title {
				font-size: 1.5em;
			}

			form {
				.field-group {
					label {
						font-size: 0.9em;
					}
				}
			}
		}
	}
}
