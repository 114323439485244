@use "static/sass/theme" as theme;
@use "static/sass/inputs" as inputs;

$form-field-height: inputs.$input-form-height;

.form-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-bottom: theme.$app-box-padding;

	&.side-form {
		height: 100%;
	}

	& .title {
		flex: 1 0 auto;
		font-size: 2rem;
		z-index: 9996;
		font-weight: bold;
		color: white;
		background-color: rgba(18, 18, 18, 1);
		padding: theme.$app-box-padding;
	}

	div {
		flex: 0 0 auto;

		&.form-row {
			flex: 1 0 auto;
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;
			justify-content: flex-start;

			div {
				flex: 0 1 auto;
				display: flex;
				flex-flow: column nowrap;
				margin-bottom: theme.$app-box-margin;
				margin-right: theme.$app-box-margin;
				width: 100%;

				@for $i from 1 through 12 {
					&.col-#{$i} {
						flex: 0
							0
							calc(
								100% /
									12 *
									#{$i} -
									#{$i *
									theme.$app-box-margin}
							);

						&:not(:last-child) {
							margin-right: calc(theme.$app-box-margin * 2);
						}
					}
				}

				&:last-child {
					margin-right: 0;
				}

				span.operator {
					flex: 0 0 auto;
					align-self: flex-end;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: theme.$app-box-margin;
					font-weight: 400;

					&::after {
						margin-left: 5px;
						margin-right: 5px;
					}
				}
			}
		}

		label {
			font-size: 14px;
			font-weight: bold;
			margin: theme.$app-box-margin 0;
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			justify-self: stretch;

			// &::after {
			// 	margin-bottom: 5px;
			// }
		}

		input,
		textarea,
		select {
			padding: 5px;
			height: inputs.$input-form-height;
			// margin-right: 5px;
			width: 100%;
			min-width: 100%;

			&.xs {
				min-width: inputs.$input-xs-width;
				width: inputs.$input-xs-width;
			}
			&.sm {
				min-width: inputs.$input-sm-width;
				width: inputs.$input-sm-width;
			}
			&.md {
				min-width: inputs.$input-md-width;
				width: inputs.$input-md-width;
			}
			&.lg {
				min-width: inputs.$input-lg-width;
				width: inputs.$input-lg-width;
			}
			&.xl {
				min-width: inputs.$input-xl-width;
				width: inputs.$input-xl-width;
				margin-right: 0;
			}

			&:disabled {
				cursor: not-allowed;
			}

			@media screen and (max-width: theme.$breakpoint-width-mobile) {
				&.xs {
					width: 50%;
				}

				&.sm {
					width: 80%;
				}

				&.md {
					width: 80%;
				}

				&.lg {
					width: 100%;
					margin-right: 0;
				}

				&.xl {
					width: 100%;
					margin-right: 0;
				}
			}
		}

		textarea {
			min-height: 80px;
		}

		&:not(:first-child) {
			input[type="checkbox"],
			input[type="radio"] {
				margin-left: theme.$app-box-margin;
			}
		}

		& .content-view {
			font-weight: 600;
			line-height: 1em;
			border: 1px solid #ccc;
			border-radius: theme.$app-box-border-radius;
			padding: calc(theme.$app-box-padding / 2)
				calc(theme.$app-box-padding / 2);
			margin-bottom: calc(theme.$app-box-margin * 2);
		}
	}
}

.form-controls {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row wrap;
	align-self: flex-end;
	justify-content: flex-end;
	align-items: center;
	margin-top: auto;
	margin-right: calc(theme.$app-box-margin * 2);
	margin-bottom: calc(theme.$app-box-margin * 1.5);

	button {
		flex: 0 0 auto;
	}

	button:not(:last-child) {
		margin-right: theme.$app-box-margin;
	}
}
