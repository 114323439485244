@use "static/sass/base" as base;
@use "static/sass/theme";

$z-index-modal: base.$z-index-modal-base;
$z-index-modal-container: base.$z-index-modal;
$z-index-modal-backdrop: base.$z-index-modal-layer;
$z-index-modal-title: 997;
$z-index-modal-content: base.$z-index-modal;
$z-index-modal-buttons: 996;
$z-index-modal-button-unit: 998;

$modal-overlay-background-color: rgba(0, 0, 0, 0.5);
$modal-border-radius: 5px;

$modal-buttons-bar-height: 4em;

$modal-width-mobile: 100%;
$modal-width-tablet: 100%;
$modal-width-desktop: 100%;
$modal-width-large-desktop: 95%;
$modal-width-xlarge-desktop: 80%;

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-modal;
	width: 100%;
	height: 100vh;
	background-color: $modal-overlay-background-color;

	&__container {
		height: 100%;
		z-index: $z-index-modal-container;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;

		&.full-screen {
			width: 100%;
			height: 100%;
			justify-content: flex-start;
		}
	}

	&__backdrop {
		position: absolute;
		top: 0;
		left: 0;
		z-index: $z-index-modal-backdrop;
		width: 100%;
		height: 100%;
		// background-color: $modal-overlay-background-color;
	}

	&__title {
		flex: 0 0 auto;
		// font-size: 2rem;
		z-index: $z-index-modal-title;
		font-weight: bold;
		color: white;
		background-color: rgba(18, 18, 18, 1);
		padding-top: theme.$app-box-padding;
		padding-bottom: theme.$app-box-padding;
		height: theme.$app-modal-title-height;

		h2 {
			line-height: 3rem;
			font-size: 2rem;
			width: $modal-width-xlarge-desktop;
			margin-left: 10%;
			margin-right: 10%;

			@media screen and (max-width: theme.$breakpoint-width-large-desktop) {
				& {
					margin-left: auto;
					margin-right: auto;
					width: $modal-width-large-desktop;
				}
			}

			@media screen and (max-width: theme.$breakpoint-width-desktop) {
				& {
					width: $modal-width-desktop;
					margin-left: calc(theme.$app-box-margin * 2);
					margin-right: calc(theme.$app-box-margin * 2);
				}
			}

			@media screen and (max-width: theme.$breakpoint-width-tablet) {
				& {
					width: $modal-width-tablet;
					margin-left: calc(theme.$app-box-margin * 2);
					margin-right: calc(theme.$app-box-margin * 2);
				}
			}

			@media screen and (max-width: theme.$breakpoint-width-mobile) {
				& {
					width: $modal-width-mobile;
					margin-left: calc(theme.$app-box-margin * 2);
					margin-right: calc(theme.$app-box-margin * 2);
				}
			}
		}
	}

	&__content {
		flex: 1 0 auto;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0;
		margin-bottom: 0;
		background-color: theme.$app-body-background-color;
		padding: theme.$app-box-padding;
		padding-bottom: calc(theme.$app-box-padding * 2);
		width: $modal-width-xlarge-desktop;
		height: calc(
			100vh - theme.$app-modal-title-height - $modal-buttons-bar-height
		);
		overflow: auto;
		z-index: $z-index-modal-content;

		&_wide {
			width: 75vw;
			height: 80vh;
		}

		// improved scrollbar
		&::-webkit-scrollbar {
			width: 10px;
		}

		@media screen and (max-width: theme.$breakpoint-width-xlarge-desktop) {
			& {
				// width: $modal-width-xlarge-desktop;
				// height: calc(
				// 	100vh - theme.$app-modal-title-height - $modal-buttons-bar-height
				// );
				// margin-top: 0;
				// margin-bottom: 0;
				// margin-left: auto;
				// margin-right: auto;
				// border: 0;
				// border-radius: unset;
				padding-bottom: $modal-buttons-bar-height;
			}
		}

		@media screen and (max-width: theme.$breakpoint-width-large-desktop) {
			& {
				width: $modal-width-large-desktop;
				height: calc(
					100vh - theme.$app-modal-title-height -
						$modal-buttons-bar-height
				);
				margin-top: 0;
				margin-bottom: 0;
				margin-left: auto;
				margin-right: auto;
				border: 0;
				border-radius: unset;
			}
		}

		@media screen and (max-width: theme.$breakpoint-width-desktop) {
			& {
				width: $modal-width-desktop;
				height: calc(
					100vh - theme.$app-modal-title-height -
						$modal-buttons-bar-height
				);
				margin-top: 0;
				margin-bottom: 0;
				margin-left: 0;
				margin-right: 0;
				border: 0;
				border-radius: unset;
			}
		}
	}

	&__buttons {
		height: $modal-buttons-bar-height;
		flex: 0 0 auto;
		justify-self: space-between;
		display: flex;
		justify-content: flex-end;
		z-index: $z-index-modal-buttons;
		margin: 0 calc(theme.$app-box-margin * 2) 0
			calc(theme.$app-box-margin * 2);
		padding-top: theme.$app-box-padding;
		padding-bottom: calc(theme.$app-box-padding / 2);
		position: fixed;
		width: calc(100% - 4 * theme.$app-box-margin);
		left: 0;
		right: 0;
		bottom: 0;
		background-color: theme.$app-body-background-color;
		border-top: 1px solid theme.$app-box-border-color;

		@media screen and (min-width: theme.$breakpoint-width-desktop) {
			& {
				position: fixed;
				padding: theme.$app-box-padding;
				padding-bottom: 0;
				margin-left: 2.5%;
				margin-right: 2.5%;
				width: auto;
			}
		}

		@media screen and (min-width: theme.$breakpoint-width-large-desktop) {
			& {
				position: relative;
				padding: theme.$app-box-padding;
				padding-bottom: calc(theme.$app-box-padding / 2);
				margin-left: auto;
				margin-right: auto;
				width: $modal-width-xlarge-desktop;
			}
		}

		.modal__button {
			flex: 0 0 auto;
			z-index: $z-index-modal-button-unit;
			padding: calc(theme.$app-box-padding / 2) theme.$app-box-padding;
			// background-color: #007bff;
			background-color: theme.$button-primary-background-color;
			color: #fff;
			border: theme.$button-border-width solid transparent;
			border-radius: 3px;
			margin: 0 0 theme.$app-box-margin theme.$app-box-margin;
			opacity: 1;
			transition: opacity 0.1s ease-in-out;
			text-transform: uppercase;

			&_cancel {
				background-color: theme.$cancel-color;
			}

			&_confirm {
				// background-color: #007bff;
				background-color: theme.$button-primary-background-color;
			}

			&:disabled {
				background-color: theme.$button-primary-background-color-disabled;
				color: theme.$button-primary-color-disabled;
			}

			&:not(:disabled):hover,
			&:not(:disabled):focus,
			&:not(:disabled):active,
			&:not(:disabled):focus-visible,
			&:not(:disabled):focus-within {
				opacity: 0.8;
			}
		}
	}
}
