@use "static/sass/theme";

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 300;
	background-color: rgba(0, 0, 0, 0.5);
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
	opacity: 0.5;

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	&_content {
		z-index: 310;

		& > * {
			z-index: 310 !important;
		}
	}

	// &__content {
	// 	position: relative;
	// 	width: 100%;
	// 	max-width: 100%;
	// 	height: 100%;
	// 	max-height: 100%;
	// 	overflow: hidden;
	// 	display: flex;
	// 	flex-direction: column;
	// 	justify-content: center;
	// 	align-items: center;
	// 	transition: all 0.3s ease-in-out;
	// 	opacity: 0;
	// 	pointer-events: none;

	// 	&.active {
	// 		opacity: 1;
	// 		pointer-events: all;
	// 	}

	// 	&__close {
	// 		position: absolute;
	// 		top: 0;
	// 		right: 0;
	// 		width: 2rem;
	// 		height: 2rem;
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	// 		cursor: pointer;
	// 		transition: all 0.3s ease-in-out;
	// 		opacity: 0;
	// 		pointer-events: none;

	// 		&.active {
	// 			opacity: 1;
	// 			pointer-events: all;
	// 		}

	// 		&:hover {
	// 			transform: scale(1.1);
	// 		}

	// 		&:active {
	// 			transform: scale(0.9);
	// 		}

	// 		&__icon {
	// 			width: 1.5rem;
	// 			height: 1.5rem;
	// 			fill: white;
	// 		}
	// 	}
	// }
}
