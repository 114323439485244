@use "static/sass/theme" as theme;

$header-spacer-height: theme.$app-header-height;
$header-spacer-height-tablet: theme.$app-header-height-tablet;

.App {
	overflow: hidden;

	.header-spacer {
		margin: 0;
		padding: 0;
		height: $header-spacer-height;

		@media screen and (max-width: theme.$breakpoint-width-tablet) {
			& {
				height: $header-spacer-height-tablet;
			}
		}
	}

	.header-spacer ~ .router-frame {
		height: calc(100vh - $header-spacer-height);
	}

	&-loading {
		overflow: hidden;
		// center a text element
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100vw;

		.loading-icon {
			flex: 0 0 auto;
			font-size: 3rem;
			color: theme.$app-title-color;
			margin-right: 1rem;
		}

		.loading-text {
			flex: 0 0 auto;
			font-size: 1.5rem;
			color: theme.$app-title-color;
		}
	}
}
