// components/ChatAssistModal.module.scss
@use "static/sass/theme" as theme;
@use "static/sass/animations" as animations;
@use "./ChatAssistFab.scss" as fab;

$app-chat-assist-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
$app-chat-assist-background-color: theme.$app-body-background-color;
$app-chat-assist-font-size: 1rem;
$app-chat-assist-modal-border-radius: 8px;
// $app-chat-assist-message-list-bottom-spacing: calc(
// 	5.25 * theme.$app-box-padding
// );
$app-chat-assist-message-list-bottom-spacing: calc(5 * theme.$app-box-padding);
$app-chat-assist-message-list-max-height: 350px;
$app-chat-assist-container-min-height: 400px;
$app-chat-assist-container-max-height: 520px;

$outline-border: 1px solid theme.$color-primary;
$glass-border-color: #5f5f5f;

$glass-background-color: rgba(227, 227, 227, 1);
$glass-background-color-translucid: rgba(218, 218, 218, 0.965);
$glass-background-color-translucid-darker: rgba(188, 188, 188, 0.975);
$glass-background-color-hover: rgba(211, 211, 211, 0.94);

.chat-assist-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: theme.$app-overlay-background-color;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	padding: 1rem;
	z-index: 999;
}

.chat-assist-modal-container {
	// background-color: $app-chat-assist-background-color;
	// background-color: rgba(255, 255, 255, 0.65);
	background-color: $glass-background-color-translucid-darker;
	border-radius: $app-chat-assist-modal-border-radius;
	box-shadow: $app-chat-assist-box-shadow;
	width: 100%;
	max-width: 400px;
	padding: theme.$app-box-padding;
	position: relative;
	animation: slideUp 0.3s ease;
	transition: all ease-in animations.$animation-duration-500;
	// transition: background-color ease-in animations.$animation-duration-500;
	// transition: filter ease-out animations.$animation-duration-500;
	filter: blur(0.3px);
	opacity: 1;

	&:hover {
		// background-color: $glass-background-color-hover;
		opacity: 1;
		filter: unset;
	}
}

@keyframes slideUp {
	from {
		transform: translateY(20px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.close-button {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	background: none;
	border: none;
	font-size: calc(1.5 * $app-chat-assist-font-size);
	cursor: pointer;
	color: #666;

	&:hover,
	&:active,
	&:focus-visible,
	&:focus {
		color: #333;
	}
}

.chat-assist-modal-title {
	margin: 0;
	color: lighten(theme.$color-primary, 4%);
	margin-bottom: 4px;
}

.chat-assist-modal-form-group {
	margin-bottom: 0.75rem;
}

.chat-assist-modal-label {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	font-weight: 500;
	font-size: $app-chat-assist-font-size;

	& > * {
		flex: 0 0 auto;
	}
}

.chat-assist-modal-textarea {
	width: 100%;
	padding: 0.75rem;
	border: 1px solid $glass-border-color;
	border-radius: 4px;
	font-family: inherit;
	resize: vertical;
	min-height: 120px;
	background-color: $glass-background-color-translucid;

	&:focus {
		outline: none;
		border-color: theme.$color-primary;
		box-shadow: fab.$fab-box-shadow-focus;
	}
}

.chat-assist-submit-button {
	background-color: theme.$color-primary;
	color: white;
	border: none;
	border-radius: 4px;
	padding: 0.75rem 1.5rem;
	font-size: $app-chat-assist-font-size;
	cursor: pointer;
	width: 100%;
	transition: background-color 0.3s;

	&:hover:not(:disabled),
	&:active:not(:disabled),
	&:focus:not(:disabled),
	&:focus-visible:not(:disabled) {
		background-color: theme.$color-primary-hover;
	}

	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}
}

.error {
	color: theme.$app-cancel-color;
	margin-bottom: 1rem;
	padding: 0.5rem;
	background-color: theme.$app-cancel-background-color;
	border-radius: 4px;
}

.success-message {
	text-align: center;
	padding: 1rem;
	color: theme.$app-success-background-color;

	p {
		margin: 0.5rem 0;
	}
}

.close-success-button {
	margin-top: 1rem;
	background-color: theme.$app-success-color;
	color: theme.$app-success-background-color;
	border: 1px solid theme.$app-success-background-color;
	border-radius: 4px;
	padding: 0.5rem 1rem;
	cursor: pointer;

	&:hover,
	&:active,
	&:focus,
	&:focus-visible {
		background-color: theme.$app-success-color-hover;
		color: theme.$app-success-color;
	}
}

.chat-assist-header {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	margin-top: 0.5rem;
	margin-bottom: 1rem;

	& > * {
		flex: 0 0 auto;

		&:not(:last-child) {
			margin-right: calc(1.5 * theme.$app-box-margin);
			margin-top: -2px;
		}
	}

	.icon {
		font-size: 1.2rem;
		font-weight: theme.$font-weight-bold;
		color: theme.$color-primary;

		&:hover,
		&:active,
		&:focus,
		&:focus-visible {
			color: theme.$color-primary-hover;
		}
	}
}

.status-indicator {
	padding: 0.45rem 0.5rem 0.25rem 0.5rem;
	border-radius: 12px;
	font-size: 0.75rem;
	line-height: 0.6rem;
	font-weight: bold;
	text-transform: uppercase;

	&.online {
		// background-color: $glass-background-color-translucid;
		background-color: lighten($glass-background-color-translucid, 4%);
		color: theme.$app-success-background-color;
		border: 1px solid theme.$app-success-border-color;
	}

	&.offline {
		background-color: $glass-background-color-translucid;
		color: theme.$app-cancel-color;
		border: 1px solid theme.$app-cancel-border-color;
	}
}

.offline-message {
	background-color: #fff8e1;
	border-left: 4px solid theme.$app-warning-background-color;
	// border-left: 4px solid #ffc107;
	padding: 0.75rem;
	margin-bottom: 1rem;
	border-radius: $app-chat-assist-modal-border-radius;

	p {
		margin: 0.35rem 0 0.35rem 0;
		font-size: $app-chat-assist-font-size;
		line-height: $app-chat-assist-font-size;
		color: #5d4037;

		&:first-child {
			font-weight: bold;
		}
	}
}

.chat-assist-history {
	margin-top: 0;
	//margin-bottom: calc(1.5 * theme.$app-box-margin);
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
	width: 100%;
	height: 100%;

	// & > * {
	// 	flex: 0 0 auto;
	// }

	.chat-history-scrollable {
		width: 100%;
		//margin-top: calc(0.5 * theme.$app-box-margin);
		// padding-bottom: $app-chat-assist-message-list-bottom-spacing;
		//margin-bottom: $app-chat-assist-message-list-bottom-spacing;
		padding-bottom: calc(0.5 * theme.$app-box-padding);
		padding-top: calc(0.25 * theme.$app-box-padding);
		padding-right: calc(0.1 * theme.$app-box-padding);
		border-top: 1px solid theme.$button-border-color;
		// height: auto;
		// max-height: calc(90vh - 250px);
		// max-height: calc(100% - 250px);
		height: auto;
		max-height: $app-chat-assist-message-list-max-height;
		overflow: hidden;
		overflow-y: auto;
		scrollbar-color: transparent;
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: stretch;

		& > * {
			flex: 0 0 auto;
		}
	}

	.chat-history-view-button,
	.chat-history-item {
		text-align: left;
		flex: 0 0 auto;
		margin-left: 1px;
		padding: calc(0.35 * theme.$app-box-padding)
			calc(0.5 * theme.$app-box-padding);
		border-radius: theme.$app-box-border-radius;
		// background-color: transparent;
		background-color: $glass-background-color-translucid;
		border: 1px solid $glass-border-color;
		margin-bottom: theme.$app-box-margin;
		width: 100%;
		height: 3.2em;
		font-size: 1rem;
		line-height: 1.2rem;

		&:hover:not(:disabled),
		&:focus:not(:disabled),
		&:focus-visible:not(:disabled),
		&:active:not(:disabled) {
			background-color: theme.$button-background-color-hover;
			border-color: theme.$button-border-color-hover;
			outline: $outline-border;
		}

		&:first-of-type {
			margin-top: theme.$app-box-margin;
		}

		&.selected {
			border-color: theme.$button-primary-background-color;
		}
	}

	.chat-history-view-button {
		width: auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		margin-top: 1px !important;

		& > * {
			flex: 0 0 auto;
		}

		span.ball-flag {
			margin-left: theme.$app-box-margin;
			padding-top: calc(0.15 * theme.$app-box-padding);
			color: theme.$app-cancel-background-color;
			background-color: theme.$app-cancel-color;
			min-width: 1.3rem;
			height: 1.3rem;
			border-radius: 50%;
			text-align: center;
		}
	}

	.chat-assist-active-ticket {
		min-height: $app-chat-assist-container-min-height;
		// max-height: calc(100vh - 200px);
		max-height: $app-chat-assist-container-max-height;
		width: 100%;

		&-title {
			font-size: 1.1rem;
			line-height: 1.2rem;
			border-bottom: 1px solid theme.$button-border-color;
			padding-bottom: calc(0.8 * theme.$app-box-padding);
			margin-bottom: calc(2 * theme.$app-box-margin);
			width: 100%;
			word-wrap: break-word;
			height: auto;
		}

		&-details {
			height: 100%;
			display: flex;
			flex-flow: column nowrap;
			// align-items: stretch;

			// margin-top: theme.$app-box-margin;
			.inline-detail {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: flex-start;
				font-size: 1.1rem;
				margin-bottom: calc(1 * theme.$app-box-margin);

				dt {
					flex: 0 0 auto;
					font-weight: theme.$font-weight-bold;
					margin-right: theme.$app-box-margin;
				}

				dd {
					flex: 0 0 auto;
					font-weight: theme.$font-weight-normal;
					padding: calc(0.35 * theme.$app-box-padding)
						calc(0.35 * theme.$app-box-padding);
					border-color: transparent;
					border-style: solid;
					border-width: 3px;
					border-radius: $app-chat-assist-modal-border-radius;
					text-transform: uppercase;
					font-size: 1rem;
					line-height: 1rem;

					&.highlight {
						background-color: $glass-background-color-translucid;
						// border: $outline-border;
					}
				}
			}
		}

		&-chat {
			width: 100%;
			margin-bottom: $app-chat-assist-message-list-bottom-spacing;
			background-color: $glass-background-color-translucid;
			border: 1px solid $glass-border-color;
			border-radius: $app-chat-assist-modal-border-radius;
			box-shadow: $app-chat-assist-box-shadow;
			max-height: $app-chat-assist-message-list-max-height;
			overflow: hidden;

			// glass effect
			//filter: blur(0.5px);
			opacity: 1;

			&:hover {
				// background-color: $glass-background-color-hover;
				opacity: 1;
				//	filter: unset;
			}

			.chat-message-group {
				min-width: 55%;
				max-width: 80%;
				margin-top: calc(1.2 * theme.$app-box-margin);
				margin-left: calc(1.2 * theme.$app-box-margin);
				width: auto;
				// overflow: hidden;
				// overflow-y: auto;
				// padding: calc(0.35 * theme.$app-box-padding);
				// border: 1px solid theme.$button-border-color;
				// border-radius: theme.$button-border-radius;
				// background-color: theme.$button-background-color;

				.chat-message-title {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: space-between;
					margin-bottom: calc(0.25 * theme.$app-box-margin);

					span.sender {
						font-size: 0.8rem;
						font-weight: theme.$font-weight-bold;
					}

					span.date-time {
						justify-self: flex-end;
						font-weight: theme.$font-weight-normal;
						font-size: 0.9em;
					}
				}

				.chat-message-comment {
					font-weight: theme.$font-weight-normal;
					font-size: 1rem;
					padding: calc(0.45 * theme.$app-box-padding)
						calc(0.5 * theme.$app-box-padding);
					border: 1px solid theme.$button-border-color;
					border-radius: theme.$button-border-radius;
					border-top-left-radius: 0;
					background-color: theme.$button-background-color-hover;
					transition: theme.$transition-all-medium;

					&:hover,
					&:active,
					&:focus,
					&:focus-visible {
						box-shadow: $app-chat-assist-box-shadow;
						cursor: pointer;
					}
				}

				&.reporter {
					align-self: flex-end;
					margin-left: calc(0 * theme.$app-box-margin);
					margin-right: calc(1.2 * theme.$app-box-margin);

					&:not(:first-of-type) {
						span.date-time {
							margin-left: auto;
						}
						span.sender {
							display: none;
						}
					}

					.chat-message-comment {
						border-top-left-radius: theme.$button-border-radius;
						border-top-right-radius: 0;
						background-color: theme.$chat-reporter-background-color;
						align-self: flex-end;
					}
				}
			}
		}
	}
}

.chat-assist-active-ticket-editor {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: stretch;
	margin-top: theme.$app-box-padding;
	padding-left: theme.$app-box-padding;
	padding-right: theme.$app-box-padding;
	padding-top: calc(0.7 * theme.$app-box-padding);
	padding-bottom: calc(0.7 * theme.$app-box-padding);
	// background-color: theme.$app-body-background-color;
	background-color: $glass-background-color-translucid-darker;
	border-bottom-left-radius: $app-chat-assist-modal-border-radius;
	border-bottom-right-radius: $app-chat-assist-modal-border-radius;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: $app-chat-assist-box-shadow;

	.chat-editor-textbox {
		flex: 1 0 auto;
		min-width: 70%;
		margin-right: theme.$app-box-margin;

		textarea {
			width: 100%;
			height: 100%;
			background-color: transparent;
			border: 1px solid theme.$color-primary;

			&:hover,
			&:active,
			&:focus,
			&:focus-visible {
				border: 1px solid theme.$color-primary;
				outline: 1px solid theme.$color-primary;
			}
		}
	}

	.chat-editor-actions {
		flex: 0 0 auto;
		width: 15%;
		// justify-self: flex-end;
	}

	.chat-editor-submit-button {
		padding: calc(0.5 * theme.$app-box-padding);
		border: 1px solid theme.$button-border-color;
		border-radius: theme.$button-border-radius;
		width: 100%;

		&:hover:not(:disabled),
		&:active:not(:disabled),
		&:focus:not(:disabled),
		&:focus-visible:not(:disabled) {
			background-color: theme.$button-background-color-hover;
			border-color: theme.$button-border-color-hover;
		}
	}
}
