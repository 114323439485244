@use "static/sass/theme" as theme;
@use "static/sass/base" as base;

.toast-message {
	position: fixed;
	right: 0;
	z-index: base.$z-index-top;
	max-width: 100%;
	padding: theme.$app-box-padding;
	pointer-events: none;

	&.top {
		top: theme.$app-header-height;
	}

	&.bottom {
		bottom: theme.$app-box-padding;
	}

	& > * {
		pointer-events: auto;
	}
}

.fluent-toast {
	box-shadow: theme.$app-header-box-shadow;
	animation: toastSlideIn 0.2s ease-out;
	margin-bottom: theme.$app-box-margin;
	border-radius: theme.$app-box-border-radius;

	&:hover {
		.ms-MessageBar-dismissal {
			opacity: 1;
		}
	}

	.ms-MessageBar-dismissal {
		opacity: 0.7;
		transition: opacity 0.2s ease;
	}

	.ms-MessageBar-content {
		padding: 8px;
	}

	.ms-MessageBar-text {
		font-size: 14px;
		font-family: theme.$font-family;
	}
}

@keyframes toastSlideIn {
	from {
		transform: translateX(100%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@media screen and (max-width: theme.$breakpoint-width-mobile) {
	.fluent-toast-container {
		left: 0;
		right: 0;
		max-width: 100%;
		padding: calc(theme.$app-box-padding / 2);

		&.bottom {
			bottom: 80px;
		}
	}

	.fluent-toast {
		margin: 0 theme.$app-box-margin;
	}
}
