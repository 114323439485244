@use "static/sass/theme" as theme;

button {
	font-family: theme.$font-family !important;
}

// .tab-button,
.button,
.btn {
	font-family: theme.$font-family !important;
	font-size: theme.$button-font-size;
	font-weight: theme.$button-font-weight;
	padding: calc(theme.$app-box-padding / 2) theme.$app-box-padding;
	margin: theme.$app-box-margin 0;
	border: 1px solid #e0e0e0;
	border-radius: theme.$button-border-radius;
	background-color: theme.$button-secondary-background-color;
	cursor: pointer;
	transition: all 0.2s ease;

	&-upper {
		text-transform: uppercase;
	}

	&:not(:disabled) {
		&:hover,
		&:active,
		&:focus,
		&:focus-visible,
		&:focus-within {
			background-color: darken(
				theme.$button-primary-background-color,
				5%
			);
			color: #fff;

			&:not(.icon) {
				text-decoration: underline;
			}
		}
	}

	&-margin-0 {
		margin: 0;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}

	&-primary {
		background-color: theme.$button-primary-background-color;
		color: theme.$button-primary-color;
	}

	&-secondary {
		&:not(:disabled) {
			background-color: theme.$button-secondary-background-color;
			color: theme.$button-secondary-color;
			&:hover,
			&:active,
			&:focus,
			&:focus-visible,
			&:focus-within {
				background-color: theme.$button-secondary-background-color;
				color: theme.$button-secondary-color;
				text-decoration: underline;
			}
		}
	}

	&.icon {
		padding: 8px 8px;
		width: 40px;

		i {
			text-align: center;
			margin: 0 auto;
			font-weight: theme.$font-weight-bolder;
		}
	}

	&.remove {
		color: theme.$remove-color;
		border-color: theme.$remove-color;

		&:hover:not(:disabled) {
			background-color: theme.$button-secondary-background-color-hover;
		}
	}
}
