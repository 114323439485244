@use "static/sass/theme" as theme;

$nav-button-unit-height: 32px;

.Header {
	position: fixed;
	top: 0;
	z-index: theme.$app-header-z-index;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	padding: theme.$app-header-padding;
	background: theme.$app-header-background-color;
	color: theme.$app-header-color;
	box-shadow: theme.$app-header-box-shadow;

	&__appTitle {
		flex: 0 0 auto;
		font-weight: theme.$font-weight-bold;
		text-decoration: none;
		color: theme.$app-header-title-color;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;

		a,
		button {
			margin: 0;
			padding: 0;
			text-decoration: none;
			color: theme.$app-header-title-color;
			transition: color theme.$app-transition-time;
			border: 0;
			background: none;
			font-size: theme.$app-title-font-size;
			font-family: theme.$font-family;

			&:hover,
			&:focus,
			&:focus-visible,
			&:focus-within {
				cursor: pointer;
				color: theme.$app-header-title-color-hover;
				text-decoration: underline;
			}
		}

		img {
			height: 2rem;
			margin-right: theme.$app-box-margin;
			border: 2px solid transparent;
			border-radius: 50%;
			transition: border 0.15s ease-in;

			&:hover {
				cursor: pointer;
				border: 2px solid white;
			}
		}
	}

	&__navButtons {
		flex: 0 0 auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-end;
		margin-left: auto;
	}

	&__navButtonUnit {
		flex: 0 0 auto;
		margin-left: theme.$margin-unit;
		height: $nav-button-unit-height;

		&.user-name-container {
			display: block;
			padding: calc(theme.$app-box-padding / 2) theme.$app-box-padding;
			border-radius: theme.$app-box-border-radius;
			background-color: rgba(255, 255, 255, 0.1);

			@media screen and (max-width: theme.$breakpoint-width-mobile-small) {
				display: none;
			}
		}

		.user-name {
			font-size: 1em;
			color: theme.$app-header-color;
		}

		button {
			padding: calc(theme.$app-box-padding / 2) theme.$app-box-padding;
			border: none;
			border-radius: theme.$button-border-radius;
			background-color: theme.$button-background-color;
			color: theme.$button-color;
			font-weight: theme.$font-weight-bold;
			cursor: pointer;
			transition: background-color 0.2s;

			&:hover {
				background-color: theme.$button-background-color-hover;
			}

			&:active {
				background-color: theme.$button-background-color-hover;
			}

			&:focus {
				outline: none;
				box-shadow: 0 0 0 2px theme.$button-focus-outline-color;
			}
		}
	}
}

@media screen and (max-width: theme.$breakpoint-width-tablet) {
	.Header {
		padding: theme.$app-header-padding-tablet;
	}
}
