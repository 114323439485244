@use "static/sass/theme" as theme;
@use "static/sass/inputs" as inputs;

form {
	label {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: theme.$app-box-margin;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-start;

		& * {
			margin-right: theme.$app-box-margin;
		}

		input[type="checkbox"],
		input[type="radio"] {
			margin-right: theme.$app-box-margin;
			outline: unset;

			// &:not(:first) {
			// 	margin-left: 5px;
			// }
		}

		&:not(:first-of-type) {
			input[type="checkbox"],
			input[type="radio"] {
				outline: unset;
				// margin-left: theme.$app-box-margin;
			}
		}
	}

	input,
	textarea,
	select {
		font-size: 14px;
		// padding: 8px 16px;
		padding: inputs.$input-padding calc(inputs.$input-padding * 2);
		margin: inputs.$input-margin 0 inputs.$input-margin 0;
		border: 1px solid #c8c8c8;
		border-radius: inputs.$input-border-radius;
		outline: none;
		min-width: 120px;

		&[type="checkbox"],
		&[type="radio"],
		button {
			min-width: unset;
		}

		&:focus:not([type="checkbox"]),
		&:focus:not([type="radio"]),
		&:focus {
			border-color: #0078d4;
			box-shadow: 0 0 0 1px #0078d4;
		}

		&::file-selector-button {
			padding: calc(theme.$app-box-padding * 0.14)
				calc(theme.$app-box-padding * 0.2);
			margin: 0 theme.$app-box-padding 0 0;
		}
	}

	.button {
		margin: inputs.$input-margin inputs.$input-margin inputs.$input-margin 0;
		min-height: inputs.$input-form-height;
	}
}
