// components/ChatFab.module.scss
@use "static/sass/theme" as theme;

$fab-height: 56px;
$fab-width: 56px;
$fab-distance-right: 1.3rem;
$fab-distance-bottom: 0.65rem;
$fab-box-shadow-focus: 0 0 0 3px rgba(63, 81, 181, 0.3);

.chat-fab {
	position: fixed;
	bottom: $fab-distance-bottom;
	right: $fab-distance-right;
	width: $fab-width;
	height: $fab-height;
	border-radius: 50%;
	background-color: theme.$button-primary-background-color;
	color: white;
	border: none;
	box-shadow: 0 4px 8px theme.$box-shadow-color;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;
	z-index: 1000;

	&:active,
	&:hover {
		background-color: theme.$button-primary-background-color-hover;
		transform: scale(1.075);
	}

	&:focus {
		outline: none;
		box-shadow: $fab-box-shadow-focus;
	}
}
